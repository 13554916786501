
/* 3.2. Social Icons */
.social-icon {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 20px;
  line-height: 1em;
  /* Sizes */
  /* Directions */
  /* Shapes */
  /* Color Schemes */
}

.social-icon li {
  display: inline-block;
  margin-right: 1.2em;
  font-size: 1em;
  line-height: 1em;
  margin-bottom: 0;
}
.social-icon li a{
  text-decoration: none;
}

.social-icon li:last-child {
  margin-right: 0;
}

.social-icon i {
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
}

.social-icon.social-icon-sm {
  font-size: 14px;
}

.social-icon.social-icon-md {
  font-size: 18px;
}

.social-icon.social-icon-lg {
  font-size: 20px;
}

.social-icon.social-icon-lg li {
  margin-right: 1em;
}

.social-icon.vertical li {
  display: block;
  margin-right: 0 !important;
  margin-bottom: 1.2em;
}

.social-icon.vertical li:last-child {
  margin-bottom: 0;
}

.social-icon.round li, .social-icon.semi-round li, .social-icon.square li, .social-icon.circle li {
  margin-right: 0.5em;
}

.social-icon.round a, .social-icon.semi-round a, .social-icon.square a, .social-icon.circle a {
  display: inline-flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #3d59e8;
  color: #fff;
}

.social-icon.round a:hover, .social-icon.semi-round a:hover, .social-icon.square a:hover, .social-icon.circle a:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -webkit-transform: translate(0, -2px);
  transform: translate(0, -2px);
}

.social-icon.round.social-icon-sm li, .social-icon.semi-round.social-icon-sm li, .social-icon.square.social-icon-sm li, .social-icon.circle.social-icon-sm li {
  margin-right: 0.3em;
}

.social-icon.round.social-icon-sm a, .social-icon.semi-round.social-icon-sm a, .social-icon.square.social-icon-sm a, .social-icon.circle.social-icon-sm a {
  width: 30px;
  height: 30px;
}

.social-icon.round.social-icon-md li, .social-icon.semi-round.social-icon-md li, .social-icon.square.social-icon-md li, .social-icon.circle.social-icon-md li {
  margin-right: 0.6em;
}

.social-icon.round.social-icon-md a, .social-icon.semi-round.social-icon-md a, .social-icon.square.social-icon-md a, .social-icon.circle.social-icon-md a {
  width: 48px;
  height: 48px;
}

.social-icon.round.social-icon-lg li, .social-icon.semi-round.social-icon-lg li, .social-icon.square.social-icon-lg li, .social-icon.circle.social-icon-lg li {
  margin-right: 0.7em;
}

.social-icon.round.social-icon-lg a, .social-icon.semi-round.social-icon-lg a, .social-icon.square.social-icon-lg a, .social-icon.circle.social-icon-lg a {
  width: 55px;
  height: 55px;
}

.social-icon.round i,
.social-icon.round a {
  border-radius: 8%;
}

.social-icon.semi-round i,
.social-icon.semi-round a {
  border-radius: 2%;
}

.social-icon.circle i,
.social-icon.circle a {
  border-radius: 50em;
}

.social-icon.scheme-dark a {
  color: #000;
}

.social-icon.scheme-dark.semi-round a, .social-icon.scheme-dark.round a, .social-icon.scheme-dark.square a, .social-icon.scheme-dark.circle a {
  background-color: #000;
  color: #fff;
}

.social-icon.scheme-gray a {
  color: #999;
}

.social-icon.scheme-gray.semi-round a, .social-icon.scheme-gray.round a, .social-icon.scheme-gray.square a, .social-icon.scheme-gray.circle a {
  background-color: #999;
}

.social-icon.scheme-white a {
  color: #fff;
}

.social-icon.scheme-white.semi-round a, .social-icon.scheme-white.round a, .social-icon.scheme-white.square a, .social-icon.scheme-white.circle a {
  background-color: #fff;
  color: #000;
}

.social-icon.branded-text a {
  background-color: transparent;
}

.social-icon.branded i {
  width: 100%;
  height: 100%;
  position: relative;
}

.social-icon.branded a {
  background-color: transparent;
  line-height: normal;
}
