img {
	max-width: 100%; /* Make sure images are scaled correctly. */
	height: auto; /* Adhere to container width. */
	vertical-align: top;
}

figcaption {
	text-align: center;
	background: #f5f5f5;
	padding: 6px 10px;
	font-size: .9em;
}