main {
	padding-top: 0;
}

.site {
	@extend %NormalTransition;
}

.page-sidebar,
.page-main-content {
	position: relative;
	min-height: 1px;
	padding-left: 25px;
	padding-right: 25px;
	width: 100%;

	ul {
		list-style-type: disc;    /* Default */
	}

	ol {
		list-style-type: decimal; /* Default */
	}
}
.smpls-sticky-column.is_stuck {
	z-index: 10!important;
}

/* Define a transition duration during page visits */
html.is-changing .transition-fade {
	transition: opacity 0.25s;
	opacity: 1;
}

/* Define the styles for the unloaded pages */
html.is-animating .transition-fade {
	opacity: 0;
}
