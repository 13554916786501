.page-open-mobile-menu {
  cursor: pointer;
}

.page-mobile-menu-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 30px;
  
  .page-close-mobile-menu {
	font-size: 45px;
	color: silver;
	position: absolute;
	top: 50%;
	right: 20px;
	margin-top: -37px;
	
	&:hover {
	  color: var(--color-primary);
	}
  }
}

.page-mobile-menu-logo {
  flex-grow: 1;
}

.page-mobile-menu-opened {
  overflow: hidden;
}

.mobile-menu-push-to-left {
  .page-mobile-main-menu {
	> .inner {
	  float: right;
	  transform: translateX(100%);
	}
  }
}

.mobile-menu-slide-to-left {
  .page-mobile-main-menu {
	> .inner {
	  float: right;
	  transform: translateX(100%);
	}
  }
}

.page-mobile-main-menu {
  transition: all 0.5s ease;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: rgba(0, 0, 0, .7);
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  
  > .inner {
	position: relative;
	height: 100%;
	max-width: 100%;
	text-align: left;
	transform: translateX(-100%);
	cursor: default;
	background: #fff;
	width: 100%;
	transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
	display: flex;
	flex-direction: column;
  }
  
  .page-mobile-menu-opened & {
	visibility: visible;
	opacity: 1;
	
	> .inner {
	  transform: translateX(0);
	}
  }
  
  ul {
	margin: 0;
	padding: 0;
  }
  
  li {
	display: block;
	list-style-type: none;
	
	&.opened {
	  > a {
		text-decoration: none;
		.toggle-sub-menu {
		  &:after {
			content: '\f106';
		  }
		}
	  }
	}
  }
  
  .sub-menu {
	display: none;
	margin: 14px 0;
	
	.sub-menu {
	  margin-left: 10px;
	}
  }
  
  .simple-menu {
	a {
	  padding: 8px 0;
	}
  }
  
  .menu__container {
	padding: 0;
	margin: 20px 0px 100px;
 
	.custom-menu a {
	  text-decoration: underline;
	}
	
	> li {
	  
	  > a {
		padding: 10px 0;
		border: 0;
		text-decoration: none;
		border-color: transparent;
		padding-top: 19px;
		padding-bottom: 19px;
		padding-left: 0;
		padding-right: 0;
		font-size: 18px;
		line-height: 1;
		font-weight: 500;
		letter-spacing: .36px;
		color: #fff;
	  }
	  
	  + li {
		> a {
		  border-top: 1px solid #ffffff26;
		}
	  }
	  
	  &.opened {
		> a {
		  color: var(--color-primary);
		  border-bottom: 1px solid transparent;
		}
	  }
	}
	
	a {
	  position: relative;
	  display: block;
	  color: #fff;
	}
  }
  
  .mega-menu {
	.p-5 {
	  padding: 2rem!important;
	}
  }
  
  .widget_nav_menu,
  .insight-core-bmw {
	li {
	  margin: 0;
	  padding: 0;
	  border: 0;
	}
  }
  
  .toggle-sub-menu {
	@extend %NormalTransition;
	position: absolute;
	top: 50%;
	right: 0;
	width: 40px;
	height: 40px;
	background: rgba(255, 255, 255, .1);
	text-align: center;
	line-height: 40px;
	transform: translate(0, -50%);
	color: silver;
 
	&:after {
	  font-family: 'Font Awesome 5 Pro';
	  content: '\f107';
	  font-size: 18px;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
	}
	
	&:hover {
	  background: rgba(255, 255, 255, .2);
	  color: #000;
	}
  }
  
  .mega-menu-content {
	color: silver;
	.theme-heading-1 {
	  color: #fff;
	}
	a {
	  color: #fff;
	}
	.container {
	  max-width: 100%;
	  
	  > .row {
		> .col-md-12 {
		  padding-left: 0;
		  padding-right: 0;
		}
	  }
	}
  }
}

.page-mobile-menu-content {
  overflow-y: auto;
  flex: 1 0 0%;
  padding: 30px;
  background: #2b2b2b;
  
  .handheld & {
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
  }
}

.desktop-menu {
  .page-open-mobile-menu {
	display: none !important;
  }
}

.mobile-menu {
  .mega-menu {
	.mega-menu-content {
	  > .container {
		padding-left: 0;
		padding-right: 0;
	  }
	}
	
	.col-xs-12 {
	  padding-left: 0;
	  padding-right: 0;
	}
  }
}
