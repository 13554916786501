/* 3.15. Fancy Boxes */
/* Content box styles */
.theme-box {
  position: relative;
  margin-bottom: 30px;

  figure {
    transition: all 1500ms;
    background-size: cover;
  }

  h3 {
    font-size: 1.5em;
    line-height: 1.5em;
    margin: 0;
  }

  p {
    margin: 0;
  }

  img {
    width: 100%;
  }

  .cb-img-container {
    position: relative;
    overflow: hidden;
  }

  .theme-box-image {
    position: relative;
  }

  .theme-box-image[data-responsive-bg=true] > img {
    visibility: hidden;
  }

  .blur-main-image {
    position: absolute;
    top: 0;
    left: 0;
  }

  .blur-image-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .blur-image-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .blur-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }

  .cb-img-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    background-size: cover;
    background-position: center;
  }

  .theme-box-footer {
    position: relative;
    z-index: 2;
  }

  .image-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0, 0, 0, .13);

    .image-content-align {
      margin: 0;
      position: absolute;
      top: 80%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;

      .title {
        display: block;
        position: relative;
        padding-bottom: .625rem;
        font-weight: 400;
        letter-spacing: .1875rem;
        text-transform: uppercase;
        color: #fff;
        opacity: 0;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          height: .0625rem;
          width: 5.3125rem;
          background-color: #fff;
          left: 50%;
          -webkit-transform: translate(-50%);
          -ms-transform: translate(-50%);
          transform: translate(-50%);
        }
      }
    }
  }

  &:hover, &.hover {
    figure {
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
    }
	  .image-content {
        background: -moz-linear-gradient(to bottom, transparent 40%, #000 100%);
        background: -webkit-linear-gradient(to bottom, transparent 40%, #000 100%);
        background: linear-gradient(to bottom, transparent 40%, #000 100%);

        .image-content-align {
          .title {
            opacity: 1;
          }
        }

	  }
    .cb-img-overlay {
      opacity: 1;
      visibility: visible;
    }

    .cb-img-btn {
      opacity: 1;
      visibility: visible;
    }



    .cb-img-btn-inner {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
      transition-delay: 0.05s;

      .btn {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
        transition-delay: 0.18s;
      }
    }
  }

  &.noimage {
    .image-content {
      height: 250px;
      position: relative;
      background: transparent !important;
    }

    .title, .price, .price * {
      color: black !important;

      &:after {
        background-color: black !important;
      }
    }
  }
}

.cb-img-btn-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cb-img-btn {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.35);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;

  .smpls-overlay-link {
    z-index: 2;
  }

  .btn {
    opacity: 0;
    -webkit-transform: scale3d(1.1, 1.1, 0);
    transform: scale3d(1.1, 1.1, 0);
  }

  .btn-naked {
    color: #fff;
  }
}

.cb-img-btn-inner {
  display: flex;
  padding: 15px;
  border: 2px solid #fff;
  position: absolute;
  top: 15px;
  left: 15px;
  bottom: 15px;
  right: 15px;
  align-items: center;
  justify-content: center;
  -webkit-transform: scale3d(1.1, 1.1, 0);
  transform: scale3d(1.1, 1.1, 0);
  transition: -webkit-transform 0.8s cubic-bezier(0.17, 0.67, 0.2, 1);
  transition: transform 0.8s cubic-bezier(0.17, 0.67, 0.2, 1);
  transition: transform 0.8s cubic-bezier(0.17, 0.67, 0.2, 1), -webkit-transform 0.8s cubic-bezier(0.17, 0.67, 0.2, 1);
}

.backgroundcliptext {
  .theme-box-classes {
    .trainer {
      i {
        padding-top: 1px;
        background: linear-gradient(to right, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%);
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
      }
    }
  }
}

.flickity-slider {
  .theme-box-classes {
    margin-bottom: 50px;
  }

  .theme-box-tour {
    margin-bottom: 50px;
  }
}

.theme-box-cell {
  &:before {
    font-size: 0.875em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: #a7a9b8;
  }
}

.theme-box-overlay {
  padding-bottom: 53.335%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 0 transparent;
  transition: box-shadow 0.3s;

  .cb-img-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    width: 102%;
    height: 102%;
    left: -1%;
    top: -1%;
  }

  .theme-box-contents {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    width: 100%;
    padding: 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-decoration: none;
  }

  .theme-box-image {
    width: 100%;
    height: 100%;
    background-position: center;
  }

  .blur-image-container {
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  .cb-overlay {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.4);
    transition: opacity 0.3s;
  }

  .theme-box-contents.centered {
    justify-content: center;
  }

  .theme-box-footer {
    margin-top: auto;
    z-index: 2;
  }

  h3 {
    font-size: 31px;
    margin-bottom: 1.5rem;
    line-height: 1.2em;
    @media screen and (max-width: 480px) {
      font-size: 22px !important;
    }
  }

  &:hover, .hover {
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.18);

    .cb-overlay {
      opacity: 0.9;
    }

    .blur-image-container {
      opacity: 1;
    }
  }
}

.theme-box-overlay {
  .cb-subtitle {
    display: inline-block;
    padding: 4px 15px;
    margin-bottom: 10px;
    background-color: #83bd10;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: white;
  }
}

.theme-box-header {
  .cb-subtitle {
    position: absolute;
    left: 40px;
    top: 40px;
    @media screen and (max-width: 480px) {
      left: 35px;
    }
  }

  .theme-box-overlay.small {
    padding-bottom: 38.335%;
  }
}

.theme-box-overlay.shadowed {
  box-shadow: 0 0 32px 0 #0000001c;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.18);
}

.theme-box-overlay.tall {
  padding-bottom: 133.335%;

  .theme-box-contents {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  h3 {
    margin-bottom: 0.5rem;
  }
}

.theme-box-overlay.normal {
  padding-bottom: 68.335%;
  @media screen and (max-width: 480px) {
    padding-bottom: 48.335%;
  }

  .theme-box-overlay.scheme-light {
    color: rgba(255, 255, 255, 0.75);

    h3 {
      color: #fff;
    }

    .cb-overlay {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  .theme-box-overlay.theme-box-overlay-alt {
    padding-left: 30px;
    padding-right: 30px;

    .theme-box-contents {
      width: 100%;
    }

    .theme-box-footer {
      margin-top: 1.5rem;
    }
  }

  .theme-box-classic {
    h3 {
      margin-bottom: 0.5rem;
    }
  }

  .theme-box-classic.text-center {
    h3 {
      margin-bottom: 1.25rem;
    }
  }

  .theme-box-custom-height {
    padding-bottom: 0 !important;
  }

  .theme-box-heading-lg {
    h3 {
      font-size: 36px;
    }
  }

  .theme-box-heading-sm {
    h3 {
      font-size: 18px;
    }
  }
}
