.page-title-bar {
  position: relative;

  &__heading {
	max-width: 900px;
	margin: 0 auto;
	text-align: center;

	@include elementor-breakpoint-down(sm) {
	  text-align: left;
	  margin: 20px auto 50px auto;
	}
  }

  &__overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
  }

  &__inner {
	padding: 0 0 25px;

	@include elementor-breakpoint-down(sm) {
	  padding: 0px;
	}

	h1 {
		font-size: 3.0rem;
	  @include elementor-breakpoint-down(sm) {
		font-size: 32px;
	  }
	}

	.anotation {
	  width: 39.91rem;
	  /* width: auto; */
	  margin: 0 auto;
	  font-weight: 500;
	  @include elementor-breakpoint-down(sm) {
		font-size: 18px;
	  }
	}
  }
}

.insight_core_breadcrumb {
  margin: 0;
  padding: 0;

  li {
	display: inline-block;

	+ li {
	  position: relative;
	  padding-left: 32px;

	  &:before {
		content: '';
		position: absolute;
		left: 12px;
		top: 50%;
		transform: translateY(-50%);
		width: 5px;
		height: 5px;
		background: currentColor;
		border-radius: 50%;
	  }
	}
  }

  a {
	@extend %LinkTransition;
  }
}
