.dropdown-menu {
  background-color: #fff;
  text-align: left;
  border: 1px solid #e7e7e7;
  -webkit-box-shadow: 0px 4px 25px 3px rgb(0 0 0 / 5%);
  box-shadow: 0px 4px 25px 3px rgb(0 0 0 / 5%);
  padding: 0.8rem 0;

	.dropdown-divider {
		border: 0;
		margin: 0;
	}

  &:before {
	display: block;
	height: 12px;
	width: 12px;
	content: "";
	position: absolute;
	top: -5px;
	left: 14px;
	background: white;
	transform: rotate(45deg);
  }

  &.right:before {
	right: 14px;
	left: auto;
  }

  &.left {
	right: auto;
	left: 0;
  }

  li {
	position: relative;

	i {
	  display: inline-block;
	  font-size: 17px;
	  width: 26px;
	}

	a {
	  display: block;
	  position: relative;
	  text-decoration: none;
	  text-transform: uppercase;
	  letter-spacing: 1px;
	  font-size: 14px;
	  padding: 0.55rem 1.5rem;
	}

	& > a {
	  color: rgb(121, 121, 121);

	  &:hover {
		color: rgb(0, 0, 0);
	  }
	}
  }

  &.dropdown-menu-underlined li a:before {
	content: '';
	display: inline-block;
	width: 100%;
	height: 1px;
	position: absolute;
	bottom: -2px;
	left: 0;
	background-color: currentColor;
	-webkit-transform-origin: right center;
	transform-origin: right center;
	-webkit-transform: scaleX(0);
	transform: scaleX(0);
	transition: -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
	transition: transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
	transition: transform 0.45s cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  }

  &.dropdown-menu-underlined li a:hover:before {
	-webkit-transform-origin: left center;
	transform-origin: left center;
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
  }
}
