@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT Book'), local('FuturaPT-Book'),
  url('../../fonts/futurapt/subset-FuturaPT-Book.woff2') format('woff2'),
  url('../../fonts/futurapt/subset-FuturaPT-Book.woff') format('woff'),
  url('../../fonts/futurapt/subset-FuturaPT-Book.ttf') format('truetype'),
  url('../../fonts/futurapt/subset-FuturaPT-Book.svg#FuturaPT-Book') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT Demi'), local('FuturaPT-Demi'),
  url('../../fonts/futurapt/subset-FuturaPT-Demi.woff2') format('woff2'),
  url('../../fonts/futurapt/subset-FuturaPT-Demi.woff') format('woff'),
  url('../../fonts/futurapt/subset-FuturaPT-Demi.ttf') format('truetype'),
  url('../../fonts/futurapt/subset-FuturaPT-Demi.svg#FuturaPT-Demi') format('svg');
  font-weight: 600;
  font-style: normal;
}

@import "../../fonts/awesome/css/v5-font-face";
@import "../../fonts/awesome/css/thin";
@import "../../fonts/awesome/css/light";
@import "../../fonts/awesome/css/brands";
@import "../../fonts/awesome/css/fontawesome";
@import "../../fonts/awesome/css/solid";
@import "../../fonts/awesome/css/sharp-regular";