h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  color: var(--color-heading);
}

h1, h2, h3, h4, h5, h6, th, [class*=hint--]:after {
  font-family: var(--font-primary);
  font-weight: 500;
  line-height: 1.3;
}

h1 {
  font-size: 2.5rem
}

h2 {
  font-size: 2.0rem
}

h3 {
  font-size: 1.5rem
}

h4 {
  font-size: 1.2rem
}

h5 {
  font-size: 1rem
}

h6 {
  font-size: 18px
}

b, strong {
  font-weight: 600
}


p {
  margin-bottom: 1.5em;
  margin-top: 0;

  &:last-child {
	margin-bottom: 0;
  }
}

dfn,
cite,
em,
i {
  font-style: italic;
}

address {
  margin: 0 0 1.5em;
}

pre {
  font-size: 15px;
  overflow: auto;
  margin-bottom: 1.6em;
  padding: 1.6em;
  max-width: 100%;
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  line-height: 1.6;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
}

code,
kbd,
tt,
var {
  font-size: 15px;
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
}

code {
  background: #f3f4f5;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: none;
  text-decoration: none;
}

big {
  font-size: 125%;
}

blockquote,
q {
  quotes: "" "";

  &:before,
  &:after {
	content: "";
  }
}

blockquote {
  margin: 50px 0 44px 48px !important;
  padding: 0 0 0 1em;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.46;
  border: 0;
  border-left: 4px solid #111;
  color: #111;
  max-width: 660px;
}

.line-througth {
  text-decoration: line-through;
}

@include media-breakpoint-down(sm) {
  blockquote {
	margin: 30px 0 30px 10px !important;
  }
}
