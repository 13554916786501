// General popup style.
.popup-close-button {
	position: fixed;
	top: 80px;
	right: 90px;
	z-index: 100000;
	visibility: hidden;
	opacity: 0;
	cursor: pointer;
	height: 40px;
	width: 40px;
	color: #111;
	transition: all .3s linear 0s;

	.burger-icon-top,
	.burger-icon-bottom {
		display: block;
		position: absolute;
		top: 19px;
		left: 5px;
		width: 29px;
		height: 3px;
		transform: translateZ(0);
		transition: transform .6s cubic-bezier(.165, .84, .44, 1);

		&:before,
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: currentColor;
			opacity: .99;
		}

		&:before {
			transform-origin: right;
			transform: scaleX(1) translateZ(0);
			transition: transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
		}

		&:after {
			transform-origin: left;
			transform: scaleX(0) translateZ(0);
			transition: transform .6s cubic-bezier(.165, .84, .44, 1);
		}
	}

	.burger-icon-top {
		transform: rotate(-45deg);
	}

	.burger-icon-bottom {
		transform: rotate(225deg);

		&:before {
			transform-origin: left;
		}

		&:after {
			transform-origin: right;
		}
	}

	&:hover {
		.burger-icon-top,
		.burger-icon-bottom {
			&:before {
				transform: scaleX(0) translateZ(0);
				transition: transform .6s cubic-bezier(.165, .84, .44, 1);
			}

			&:after {
				transform: scaleX(1) translateZ(0);
				transition: transform .6s cubic-bezier(.165, .84, .44, 1) .2s;
			}
		}
	}
}

.page-popup {
	transition: all .35s ease-in-out;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99999;
	visibility: hidden;
	overflow: hidden;
	overflow-y: auto;
	opacity: 0;
	transform: scale(.8);

	&.open {
		visibility: visible;
		opacity: 1;
		transform: scale(1);
		transition: all .5s ease-in-out;

		.popup-close-button {
			visibility: visible;
			opacity: 1;
			transition-delay: .6s;
		}
	}
}

@include media-breakpoint-down(sm) {
	.popup-close-button {
		top: 30px;
		right: 30px;
	}
}

.mfp-content {
  margin: 25px auto!important;
}
.mfp-bg.dark-wrapper {
  background: rgba(255, 255, 255, 0.47);
}

.dark-wrapper, .popup-module {
  .mfp-content {
	width: 430px !important;
	max-width: 80%;
	background-color: #202126;
	background: #202126 url(/assets/images/popup-bg.jpg) repeat;
	background-position: 0% 0%;
	padding: 50px 40px;
	background-size: cover;
	-webkit-box-shadow: 1px 1px 60px rgb(0 0 0 / 66%);
	-moz-box-shadow: 1px 1px 60px rgba(0,0,0,.66);
	box-shadow: 1px 1px 60px rgb(0 0 0 / 66%);
	color: #fff;

	a {
	  color: #fff;
	}

	.title {
	  text-align: center;
	  color: white;
	  margin: 0 0 10px 0;
	}

	label {
	  color: white;
	}

	.btn {
	  color: white;
	}

	input {
	  color: white;
	  border-color: #ffffff61!important;
	}

	.popup-anotation {
	  text-align: center;
	  color: white;
	  margin: 0 0 40px 0;
	}

	.lost-pass {
	  padding: 10px 0 0 0;
	  text-align: center;
	  display: block;
	}
  }

  .mfp-close {
	font-size: 40px;
	color: #fff;
	display: inline-block;
	line-height: 0;
	padding: 0;
	border: none;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
	right: 40px;
	top: 10px;
  }

  &.wider {

	.mfp-content {
	  width: 1000px !important;
	}
  }
}



.white-wrapper {
  .mfp-content {

	width: 430px !important;
	max-width: 80%;
	background-color: #fff;
	padding: 50px 40px;
	-webkit-box-shadow: 1px 1px 60px rgb(0 0 0 / 66%);
	-moz-box-shadow: 1px 1px 60px rgba(0,0,0,.66);
	box-shadow: 1px 1px 60px rgb(0 0 0 / 66%);
	color: #fff;

	a {
	  color: #fff;
	}

	.title {
	  text-align: center;
	  color: black;
	  margin: 0 0 10px 0;
	}

	label {
	  color: black;
	}

	.btn {
	  color: white;
	}

	input {
	  border-color: #bfbfbf!important;
	  color: black;
	}

	.popup-anotation {
	  text-align: center;
	  color: black;
	  margin: 0 0 40px 0;
	}
  }

  .mfp-close {
	font-size: 40px;
	color: #000;
	display: inline-block;
	width: auto;
	height: auto;
	line-height: 0;
	padding: 0;
	border: none;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
	right: 40px;
	top: 10px;
  }
}

.service-form {
  .mfp-content {

	width: 1200px!important;
	background: #1d1d1d;
	padding: 0;
	color: #fff;

	.form-block-1 {
	  padding: 50px;
	}

	.form-block {
	  padding: 50px;
	  border-radius: 0 10px 10px 0;
	}
	.div-block {
	  height: 100%;
	  padding: 30px;
	  border-color: transparent;
	  border-radius: 10px;
	  background-color: #383838;
	}

	label {
	  color: #fff;
	}

	h3 {
	  color: #fff;
	  margin: 0 0 35px;
	  font-family: "Raleway", Sans-serif;
	  font-size: 45px;
	  font-weight: 200;
	}
	h4 {
	  color: #fff;
	  font-size: 18px;
	}

	input[type=text], input[type=email], select {
	  display: block;
	  width: 100%;
	  padding: 10px 15px!important;
	  margin-bottom: 10px!important;
	  color: #333;
	  vertical-align: middle;
	  background-color: #fff;
	  border: 1px solid #ccc;
	  font-size: 14px;
	  border-radius: 3px!important;
	  box-shadow: 0 6px 11px #00000040;
	}
  }
}

.facebook-login, .google-login {
  height: 50px;
  overflow: hidden;
  position: relative;
  margin: 0 0 10px;
}

.cart-wrapper {
  .mfp-content {
	width: 600px !important;
	padding: 10px 20px 0px 20px;
	background: #ffffff;
	max-width: 80%;

	@media (max-width: 468px) {
	  max-width: 100%;
	}
  }

  .title {
	text-align: center;
  }

  .mfp-close {
	font-size: 40px;
	display: inline-block;
	color: #000;
	display: inline-block;
	width: 60px;
	height: 60px;
	line-height: 42px;
	border: none;
	margin: 0px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
	opacity: 0.7;
	filter: alpha(opacity=70);

	&:hover, &:focus {
	  opacity: 1;
	  filter: alpha(opacity=100);
	}
  }

  .popup-added-content {
	padding: 20px 5px;

	h4 {
	  font-size: 27px;
	}

	.name {
	  margin-top: 10px;
	  margin-bottom: 25px;
	  font-size: 20px;
	  font-weight: 800;
	  margin-top: 0px;
	  margin-bottom: 10px;
	}

	.image {
	  position: relative;
	  idth: 100px;
	  height: 100px;
	  display: block;
	  text-align: center;
	  margin: 0 0 10px 0;

	  i {
		position: absolute;
		background: #fb9c1b;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		text-align: center;
		line-height: 22px;
		bottom: 10px;
		right: 25px;

		&:before {
		  content: "✓";
		  color: #fff;
		  font-size: 15px;
		  top: 1px;
		  position: relative;
		  font-weight: 800;
		}
	  }
	}
  }

  .popup-added-footer {
	float: left;
	width: calc(100% + 40px);
	margin: 0 -20px;
	padding: 18px 30px;
	text-align: center;
	color: white;

	.back-link {
	  text-decoration: none;
	  cursor: pointer;
	  margin: 11px 0;
	  color: black;

	  i {
		margin-right: 8px;
		font-size: 12px;
	  }

	}
  }
}

.cart-wrapper .free-shipping-calc {
  width: 100%;
}

.quickview-wrapper .mfp-content {
  position: relative;
  width: 95%;
  max-width: 600px;
  margin: 25px auto;
  padding: 40px;
  background-color: #fff;
  box-sizing: border-box;

  .mfp-close {
	font-size: 40px;
	display: inline-block;
	color: #000;
	width: auto;
	height: auto;
	line-height: 0;
	padding: 0;
	border: none;
	-webkit-transition: all 0.3s ease-in-out 0s;
	-o-transition: all 0.3s ease-in-out 0s;
	transition: all 0.3s ease-in-out 0s;
  }

  section.product {
	box-shadow: none;

	.product-images {
	  float: none;
	  width: 100%;
	  margin: 0 0 25px 0;
	}

	.product-summary {
	  margin-bottom: 2em;
	  float: none;
	  width: 100%;
	  clear: none;
	}
	p.price {
	  text-align: center;
	}
  }

  .quantity-button-wrapper {
	justify-content: center;
  }
  .product-summary-foot {
	justify-content: center;
  }

  .theme-heading-2 {
	font-size: 46px;
  }

  section.product .tabs {
	padding: 0;
	padding-left: 0px;
	padding-right: 0px;

	.tabs-nav {
	  width: 100%;
	  flex-flow: row nowrap;
	  border-top:1px solid #f2f2f2;
	  border-bottom:1px solid #f2f2f2;
	  justify-content: space-evenly;

	  li {
		border: none;
		margin: 0 20px 0 0;

		a {
		  padding: 14px 20px;
		}
	  }
	}
	.tabs-content {
	  width: 100%;
	  margin-top: 20px;
	  padding-left: 0px
	}
  }
}

#cart-free-shipping {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

#free-shipping-box p {
  color: #000;
  font-size: 17px;
  width: 100%;
  min-height: 40px;
  padding: 15px;
  margin: 0;
}

#free-shipping-box p strong {
  color: #282828;
  font-weight: 600;
}

#free-shipping-box .counter {
  background: #d4d4d4;
  width: 100%;
  height: 6px;
  border-radius: 3px;
  margin-top: 5px;
  float: left;
  width: 100%;
}

#free-shipping-box .counter .inner {
  background: #3db23c;
  float: left;
  height: 6px;
  border-radius: 3px;
}

#free-shipping-box span {
  float: right;
  color: #909090;
  font-size: 14px;
  margin-top: 15px;
}

@media (max-width: 578px) {
  .cart-wrapper .image {
	width: 100%;
	margin: 0 0 20px 0;
  }

  .cart-wrapper .footer .btn-success, .cart-wrapper .footer .wfg-button.wfg-add-gifts {
	display: block;
	width: 100%;
  }
  .dark-wrapper .mfp-content {
	width: 430px !important;
	max-width: 98%;
	background-color: #202126;
	padding: 20px;
	background-size: auto;
	color: #c5c5c5;

	margin: 20px 0 0 0;
  }
  .dark-wrapper .mfp-content {
	width: 430px !important;
	max-width: 98%;

	padding: 20px;
	background-size: auto;
	color: #c5c5c5;

	margin: 20px 0 0 0;
  }

  .service-form .mfp-content .form-block-1 {
	padding: 10px;
  }

  .header_customer_login, .header_customer_lostpass, .header_customer_register {
	padding: 30px 10px!important;
  }

  .cart-wrapper .name h4 {
	font-size: 20px;
  }

  .service-form-response {
	font-size: 21px;
	text-align: center;
	padding: 50px;
  }
}

