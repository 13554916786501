
/* 3.59. Bullet List */
.one-bullet-list {
  margin-bottom: 30px;
}

.one-bullet-list li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.one-bullet-list li:before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 4px;
  margin-right: 1em;
  border-radius: 50em;
  background-color: #3d59e8;
}

.one-bullet-list .inline-nav li {
  display: inline-flex;
}

.one-bullet-list .inline-nav li + li {
  margin-left: 2.5em;
}
