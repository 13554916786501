
/* 3.44. Custom Menu */
.custom-menu li a {
  display: block;
  text-decoration: none;
}

.custom-menu.menu-items-have-fill a {
  padding: 0.5em 1em;
}

.custom-menu[data-move-element] {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visiblity 0.3s;
}

.custom-menu[data-move-element].element-was-moved {
  opacity: 1;
  visibility: visible;
}

.sticky-menu {
  width: 100%;
  padding: 1em;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: #f6f6f6;
}

.reset-ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.inline-nav > li {
  display: inline-block;
}

.inline-nav li a {
  display: inline;
}

.comma-sep-li > li {
  display: inline-block;
}

.comma-sep-li > li:not(:last-child):after {
  content: ',';
  margin-right: 0.25em;
}
