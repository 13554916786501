@tailwind base;
@tailwind components;
@tailwind utilities;
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Settings
# Tools
# Vendor
# Generic
# Elements
# Objects
# Components
# Trumps
--------------------------------------------------------------*/
/*
Settings store all variables, config switches, etc.
Split up into logical sections, the smaller and simpler the better.
*/
@import "settings/global";
@import "settings/bootstrap";

/*
Tools store all Sass functionality.
If your tooling is simplistic enough, you can do something like:
  tools.mixins
If your tooling is more complex you can split it up like:
  tools.grid
  tools.typography
  etc.
*/
@import "tools/mixins-base";
@import "tools/elementor-breakpoints";
/*
Generic rules are project-agnostic.
These can be copied from any project to the next
*/
@import "~bootstrap/scss/bootstrap";
@import '~magnific-popup/dist/magnific-popup.css';
@import "vendor/spinkit/spinkit";
@import "~photoswipe/dist/photoswipe.css";
@import "~photoswipe/dist/default-skin/default-skin.css";
@import "~slick-carousel/slick/slick";
@import "~@tarekraafat/autocomplete.js/dist/css/autoComplete.css";

@import "generic/normalize";
@import "generic/fonts";
@import "generic/base";
/*
Elements are rules for classless HTML tags.
There can exist no classes at this layer, only further into our triangle.
*/
@import "elements/all";
/*
Objects are "formless", in other words invisible to the user, no cosmetics
  Eg. wrappers, grid systems, etc.
This is the first layer of classes and should be agnostically named
  Eg. list-inline, ui-list, etc.
*/
@import "objects/all";
/*
Components are the stuff of UI. Eg. buttons, special titles, etc.
These get much more explicitly named: eg. products-list, etc.
*/
@import "components/all";
@import "templates/all";
/*
Widgets
*/
@import "widgets/all";
