a {
  @extend %NormalTransition;
  color: var(--color-link);
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
	outline: 0;
	color: var(--color-link)-hover;
  }

  &[href^=tel] {
	text-decoration: none;
  }
}
a.underlined {
  border-bottom: 1px solid #c0c0c04d;

  &:hover,
  &:focus,
  &:active {
    border-bottom: 1px solid black;
  }
}
.link-secret {
  color: inherit !important;
}
