.mfp-close-btn-in .mfp-close {
  color: #000;
  font-size: 48px;
  width: 64px;
  height: 64px;
  border-radius: 0 7px 0 21px;
}
.mfp-close-btn-in .mfp-close:hover {
  color: #fff;
}
.mfp-popup .mfp-content  {
  background: white;
  border-radius: 30px 10px;
  max-width: 500px;
  margin: 0 auto;
  padding: 50px;
  color: black; position: relative;
}

@include media-breakpoint-down(sm) {
  .mfp-ajax-holder .mfp-content, .mfp-inline-holder .mfp-content {
    width: 95%;
    margin: 20px auto;
    padding: 30px;
  }
}
