.site-scroll-up {
	@extend %NormalTransition;
	position: fixed;
	right: 30px;
	bottom: -60px;
	z-index: 999;
	//box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
	display: block;
	padding: 0;
	width: 50px;
	height: 50px;
	text-align: center;
	font-size: 25px;
	line-height: 50px;
	cursor: pointer;
	opacity: 0;
	visibility: hidden;
	overflow: hidden;
  background: #46464652;

	i {
		@extend %NormalTransition;
		position: absolute;
		top: 50%;
		left: 50%;
		color: #ffffff;
	}

	.arrow-top {
		transform: translate(-50%, -50%);
	}

	.arrow-bottom {
		transform: translate(-50%, 80px);
	}

	&.show {
		bottom: 30px;
		opacity: 1;
		visibility: visible;
	}

	&:hover {
		.arrow-top {
			transform: translate(-50%, -80px);
		}

		.arrow-bottom {
			transform: translate(-50%, -50%);
		}
	}
}
