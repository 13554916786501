.selected-sizes {
  ul {
    list-style: disc;
  }
}
.quantity-button-wrapper {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  .detail-availability {

  }

  @media (max-width: 468px) {
    justify-content: center;
  }

  & > label {
    color: #111;
    display: inline-block;
    margin: 0;
    min-width: 81px;
    padding-right: 15px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.38px;
  }

  .quantity {
    display: inline-block;
    position: relative;
    min-width: 66px;
    max-width: 90px;

    @media (max-width: 468px) {
      margin: 0;
    }

    button {
      background: 0 0;
      position: absolute;
      right: 0;
      padding: 0;
      width: 20px;
      height: 20px;
      border: 0;
      font-size: 0;
      box-shadow: none;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;

      &:before {
        content: '';
        position: absolute;
        right: 7px;
        z-index: 1;
        border-left: 4px solid transparent !important;
        border-right: 4px solid transparent !important;
      }
    }

    .qty {
      padding: 2px 20px 2px 5px;
      width: 100%;
      min-height: 50px;
      border: 1px solid #eee;
      text-align: center;
      font-size: 15px;
      color: #111;
    }

    .increase {
      top: 0;

      &:before {
        bottom: 7px;
        border-bottom: 4px solid #d8d8d8;
      }
    }

    .decrease {
      bottom: 0;

      &:before {
        top: 7px;
        border-top: 4px solid #d8d8d8;
      }
    }
  }
}

table.shop_table {
  border: 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  text-align: left;
  width: 100%;
  border-collapse: separate;

  th {
    padding: 10px;
  }

  td {
    vertical-align: middle;
    border: 0;
    border-top: 1px solid #ededed;
    overflow: hidden;
    padding: 10px
  }

  .image-link {
    margin: 0 15px 0 0px;
  }

  td.product-price {
    color: #252525;
    font-weight: 600;
    font-size: 18px
  }

  td input {
    line-height: inherit
  }

  .product-remove .remove {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 0;
    line-height: 35px;
    text-align: center;
    font-size: 18px;
    color: #252525 !important;
    -webkit-transition: all .2s ease-in-out 0s;
    -o-transition: all .2s ease-in-out 0s;
    transition: all .2s ease-in-out 0s;
    margin: 0;
    text-decoration: none;
  }

  tr:hover .remove {
    opacity: 1;
  }

  .product-remove .remove:active,
  .product-remove .remove:hover {
    color: #e44343 !important
  }

  tbody .product-subtotal,
  th {
    color: #6f6f6f;
    text-align: left;
    border: 0;
  }

  tbody .order-total .shop-amount {
    color: #222
  }

  tbody div.quantity .qty {
    height: 40px;
  }

  tbody .product-name {
    font-size: 20px;
  }

  tbody .product-name a {
    text-decoration: none;
    display: block;
  }

  tbody .cart-subtotal .shop-amount,
  tbody .order-total .shop-amount {
    font-size: 20px;
    font-weight: 400
  }

  .list-bundles {
    font-size: 14px;
    list-style: none;
    padding-left: 25px
  }

  .list-bundles ul {
    list-style: inside none disc;
    padding: 0;
    margin: 0
  }
}

.load_from_ares {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 11px;
  color: black;
  font-weight: 600;
  position: absolute;
  left: 12px;
  cursor: pointer;
  z-index: 80;
  bottom: -25px;

  @media (max-width: 468px) {
    left: auto;
    right: 0;
    bottom: 12px;
  }
}

.shop-cart .coupon,
.shop-checkout .coupon {
  position: relative;
  margin: 10px 0 0 0;

  .input-text {
    height: 45px;
    width: 100%;
    background: 0 0;
    border-width: 1px 1px 1px 1px;
    padding: 8px 23px;
    margin: 0 -2px 0 0;
    border-color: #d6d6d6;
    box-shadow: none;
    border-style: solid;
  }

  .btn {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 14px;
  }
}

.cart-info {

  &.success {
    color: green;
  }
}

.steps {
  /*border: 1px solid #efefef;*/
  margin-bottom: 2em;

  .steps-number {
    display: inline-flex;
    width: 44px;
    height: 44px;
    margin-right: 1.2727272727em;
    background-color: rgba(0, 0, 0, .3);
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: 22px;
    line-height: 1;
    font-weight: 400;
    color: #fff
  }

  .steps-item {
    display: inline-flex;
    padding: 36px 30px;
    position: relative;
    flex: 1 auto;
    align-items: center;
    font-size: 23px;
    line-height: 1.5em;
    font-weight: 700;
    color: #333437;

    svg {
      position: absolute;
      top: 50%;
      right: 0;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%)
    }
  }

  .steps-item.is-active .steps-number {
    background-color: var(--color-primary);
  }

  .steps-inner {
    display: flex;
    align-items: center;
    padding: 0 30px
  }
}

.checkout #content {
  padding-top: 70px;
  padding-bottom: 70px
}
.checkout .titlebar + #content {
  padding-top: 40px
}

form.checkout {
  h3.review-title {
    margin: 1.5em 0 .7142857143em;
    font-size: 35px;
    line-height: 1.5em;
    font-weight: 600
  }

  h4.review-title {
    margin: 1.5em 0 .7142857143em;
    font-size: 25px;
    line-height: 1.5em;
    font-weight: 600
  }

  h5 {
    text-transform: uppercase;
    font-weight: 400;
    margin: 30px 0 10px 0px;
    font-size: 16px;
  }

  .form-row {
    display: flex;
    flex-flow: row wrap;
    flex: 1 auto;
    align-items: flex-end;
    margin-bottom: 13px;

    label,
    .input-wrapper {
      flex: 1 0 100%
    }

    label {
      font-size: 15px;
      font-weight: 500
    }

    label .required {
      color: #d0021b;
      font-weight: 700;
      border: 0 !important;
      text-decoration: none;
    }

    label.checkbox {
      display: flex;
      align-items: flex-start;
      line-height: 1.5em
    }

    label.checkbox input {
      width: auto;
      height: auto;
      position: relative;
      margin: .35em 10px 0 0
    }

    .select2 {
      max-width: 100%
    }

    .select2-selection--single,
    .input-text,
    textarea {
      padding: 15px 25px;
      border: 1px solid #e1e1e1;
      border-radius: 0;
      background: 0 0
    }

    .select2-selection--single:focus,
    .input-text:focus,
    textarea:focus {
      border-color: var(--color-primary)
    }

    textarea.input-text {
      height: auto
    }

    .select2-selection--single,
    .input-text {
      display: flex;
      height: 45px;
      padding-top: 0;
      padding-bottom: 0;
      align-items: center
    }

    .select2-container--open .select2-selection--single {
      border-color: transparent;
      background-color: #f9f9f9;
      color: #3c3c3c;
      border-radius: 5px 5px 0 0
    }

    &.form-row-wide {
      width: 100%
    }
  }

  .billing-fields__fiewrapper {
    display: flex;
    flex-flow: row wrap
  }

  #delivery_billing_same, #register_customer, #billing_info {
    cursor: pointer;

    label {
      cursor: inherit;
      color: black;
      text-decoration: underline;
    }

  }

  #payment, #shipping {
    padding: 0 40px 30px;
    border-radius: 0;
    background-color: #fff;

    div.form-row,
    ul.payment_methods {
      padding: 0
    }

    ul.payment_methods {
      border: none;
      margin-bottom: 1.75em;
      margin: 0;
      list-style: none outside;
    }

    ul.payment_methods li {
      line-height: 1.5;
      text-align: left;
      margin: 0;
      font-weight: 400;
    }

    ul.payment_methods li .radio-input {
      margin-right: 18px
    }

    ul.payment_methods li .payment_method_li_head {
      display: flex;
      padding: 12px 0;
      flex-flow: row wrap;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, .05)
    }

    ul.payment_methods li input {
      margin: 0
    }

    ul.payment_methods li label {
      margin-bottom: 0px;
      font-size: 17px;
      font-weight: 700;
      color: #000;
      flex: 1 auto
    }

    ul.payment_methods li p {
      margin-top: 10px;
    }

    ul.payment_methods .SavedPaymentMethods-saveNew {
      align-items: center
    }

    ul.payment_methods .SavedPaymentMethods-saveNew input {
      margin-right: 10px
    }

    ul.payment_methods .credit-card-form label {
      flex-grow: 0;
      margin-right: 5px
    }

    ul.payment_methods .credit-card-form .stripe-elements-field,
    ul.payment_methods .credit-card-form .stripe-card-group {
      flex-grow: 1
    }

    div.payment_box {
      padding: 0;
      margin: 1em 0;
      background: 0 0;
      font-size: 15px;
      line-height: 1.7333333333em;
      color: #454545
    }

    div.payment_box:before {
      content: none
    }

    .place-order {
      border: none
    }

    .place-order .privacy-policy-text {
      font-size: 13px;
      line-height: 1.6923076923em
    }

    .place-order .privacy-policy-text a {
      color: var(--color-primary)
    }

    #place_order {
      float: none;
      width: 100%;
      margin-top: 1.5em;
      background-color: var(--color-primary);
      justify-content: center;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: .1em;
      text-align: center;
      color: #fff
    }
  }

  .payment_method.disabled * {
     cursor: not-allowed;
    opacity: 0.8;
  }
}

.checkout-review-order-table {
  padding: 0 40px 40px;
  margin: 0;
  border: none;
  border-radius: 0;
  background-color: #f9f9f9;
  font-size: 15px;
  color: #000;

  &.summary {
    background-color: #f1f1f1;
  }

  th,
  td {
    border: none
  }

  th {
    padding-left: 0;
  }

  td {
    padding-left: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, .05)
  }

  tbody .product-total {
    text-align: right
  }

  tfoot td,
  tfoot th {
    padding-left: 0
  }

  td:first-child,
  th:first-child {
    text-align: left;
    padding-left: 0;
  }

  td:last-child,
  th:last-child {
    text-align: right;
    padding-right: 0;
  }

  tfoot .cart-subtotal th,
  tfoot .cart-subtotal td {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px dashed #d4d4d4;
  }

  tfoot .cart-subtotal .amount {
    font-size: 20px
  }

  tfoot .order-total th,
  tfoot .order-total td {
    padding-top: 30px;
    border-top: 1px dashed #d4d4d4
  }

  tfoot .order-total .amount {
    font-size: 30px
  }
}

.order_review_heading {
  padding: 1.02em 40px 1.28em;
  margin-bottom: 0;
  position: relative;
  background-color: #fff;
  font-size: 35px;

  /*&:before {
	content: '';
	display: block;
	position: absolute;
	top: -14px;
	right: 0;
	left: 0;
	height: 14px;
	background: linear-gradient(-45deg, #f8f8f8 33.333%, transparent 33.333%, transparent 66.667%, #f8f8f8 66.667%), linear-gradient(45deg, #f8f8f8 33.333%, transparent 33.333%, transparent 66.667%, #f8f8f8 66.667%);
	background-size: 12px 40px;
	background-position: 0 -20px
  }*/

  &.summary {
    background-color: #f1f1f1;
  }

  /*&.summary:before {
	background: linear-gradient(-45deg, #f1f1f1 33.333%, transparent 33.333%, transparent 66.667%, #f1f1f1 66.667%), linear-gradient(45deg, #f1f1f1 33.333%, transparent 33.333%, transparent 66.667%, #f1f1f1 66.667%);
	background-size: 12px 40px;
	background-position: 0 -20px;
  }*/
}

form.checkout_coupon {
  margin-top: -1em;
  border: 1px dotted #d4d4d4;
  border-top: none;
  border-radius: 0;

  p.form-row {
    width: auto !important;
    float: left !important
  }

  input {
    font-size: 13px;
    padding: 1.05em 2.1em;
    min-width: 320px;
    border: 1px solid #e1e1e1
  }
}
