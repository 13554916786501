table {
	margin: 0 0 1.5em;
	width: 100%;
}

td, th {
	border: 1px solid rgba(0, 0, 0, .08);
	padding: 10px;
	vertical-align: middle;
}

th {
	font-weight: 600;
}

table {
	th {
		&.empty {
			visibility: hidden;
			border: 0;
			background: none;
		}
	}
	
	tr:nth-child(2n) {
		td {
			background: rgba(0, 0, 0, .01);
		}
	}
}

