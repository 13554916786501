:root {
  --global-palette1: #9C6644;
  --global-palette2: #7F5539;
  --global-palette3: #B08968;
  --global-palette4: #DDB892;
  --global-palette5: #E6CCB2;
  --global-palette6: #EDE0D4;
  --global-palette7: #22223B;
  --global-palette8: #4A4E69;
  --global-palette9: #9A8C98;
  --global-palette10: #C9ADA7;
  --global-palette11: #F2E9E4;
  --color-text: #363434;
  --color-heading: #131313;
  --color-link: var(--global-palette3);
  --color-link-hover: var(--global-palette2);
  --color-primary: var(--global-palette1);
  --color-secondary: var(--global-palette8);
  --color-gradient-start: var(--global-palette1);
  --color-gradient-stop: var(--global-palette2);
  --font-primary: "Futura PT", Helvetica, Arial, sans-serif;
}

$breakpoint-sm: 544px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1920px;