@mixin normal-transition {
	transition: all .25s cubic-bezier(.645, .045, .355, 1);
}

%NormalTransition {
	@include normal-transition();
}

@mixin fast-transition {
	transition: all .15s cubic-bezier(.645, .045, .355, 1);
}

%FastTransition {
	@include fast-transition();
}

@mixin clearfix() {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

%clearfix {
	@include clearfix;
}

@mixin absolute {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

%Absolute {
	@include absolute();
}

%ContentAbsolute {
	content: '';
	@include absolute();
}

// Mixin for link transition

@mixin link-transition {
	position: relative;

	&:after {
		content: '';
		width: 0;
		height: 1px;
		position: absolute;
		left: auto;
		right: 0;
		bottom: 0;
		transition: width .6s cubic-bezier(.25, .8, .25, 1) 0s;
		background: currentColor;
	}

	&:hover {
		&:after {
			width: 100%;
			left: 0;
			right: auto;
		}
	}
}

%LinkTransition {
	@include link-transition();
}

// Mixin for font awesome version 5.x

@mixin FontAwesome {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}

%FontAwesomeSolid {
	@include FontAwesome();
	font-family: 'Font Awesome 5 Pro';
	font-weight: 900;
}

%FontAwesomeLight {
	@include FontAwesome();
	font-family: 'Font Awesome 5 Pro';
	font-weight: 300;
}

%FontAwesomeRegular {
	@include FontAwesome();
	font-family: 'Font Awesome 5 Pro';
	font-weight: 400;
}

%FontAwesomeBrands {
	@include FontAwesome();
	font-family: 'Font Awesome 5 Brands';
	font-weight: 400;
}

%FontAwesomeSolidImportant {
	@include FontAwesome();
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 900;
}

%FontAwesomeLightImportant {
	@include FontAwesome();
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 300;
}

%FontAwesomeRegularImportant {
	@include FontAwesome();
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;
}

%FontAwesomeBrandsImportant {
	@include FontAwesome();
	font-family: 'Font Awesome 5 Brands' !important;
	font-weight: 400;
}

@mixin cursor-close {
	//cursor: url('/assets/images/cursor/light-close.png') 16 16, pointer;
}

%CursorClose {
	@include cursor-close();
}

.clearfix {
  clear: both;
}

// Sizing shortcuts

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin square($size) {
  @include size($size, $size);
}

// Opacity

@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: alpha(opacity=$opacity-ie);
}


//== Translate X - Y - Z
//==========================================
@mixin translateX($x) {
  -webkit-transform: translateX($x);
  -ms-transform: translateX($x); // IE9 only
  -o-transform: translateX($x);
  transform: translateX($x);
}

@mixin translateY($y) {
  -webkit-transform: translateY($y);
  -ms-transform: translateY($y); // IE9 only
  -o-transform: translateY($y);
  transform: translateY($y);
}

@mixin translateZ($z) {
  -webkit-transform: translateZ($z);
  -ms-transform: translateZ($z); // IE9 only
  -o-transform: translateZ($z);
  transform: translateZ($z);
}

//== Transform
//==========================================
@mixin transform($argument){
  -webkit-transform: ($argument);
  -moz-transform: ($argument);
  -ms-transform: ($argument);
  -o-transform: ($argument);
  transform: ($argument);
}

@mixin text-shadow ($string: 0 1px 3px rgba(0, 0, 0, 0.25)) {
  text-shadow: $string;
}
@mixin box-shadow ($string) {
  -webkit-box-shadow: $string;
  -moz-box-shadow:    $string;
  box-shadow:         $string;
}
@mixin drop-shadow ($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25) {
  -webkit-box-shadow:	$x $y $blur $spread rgba(0, 0, 0, $alpha);
  -moz-box-shadow:	$x $y $blur $spread rgba(0, 0, 0, $alpha);
  box-shadow:		$x $y $blur $spread rgba(0, 0, 0, $alpha);
}
@mixin inner-shadow ($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25) {
  -webkit-box-shadow: inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
  -moz-box-shadow:    inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
  box-shadow:         inset $x $y $blur $spread rgba(0, 0, 0, $alpha);
}

@mixin box-sizing ($type: border-box) {
  -webkit-box-sizing: $type;
  -moz-box-sizing:    $type;
  box-sizing:         $type;
}

@mixin border-radius ($radius: 5px) {
  -webkit-border-radius: $radius;
  -moz-border-radius:    $radius;
  border-radius:         $radius;

  -moz-background-clip:    padding;
  -webkit-background-clip: padding-box;
  background-clip:         padding-box;
}
@mixin border-radiuses ($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
  -webkit-border-top-right-radius:    $topright;
  -webkit-border-bottom-right-radius: $bottomright;
  -webkit-border-bottom-left-radius:  $bottomleft;
  -webkit-border-top-left-radius:     $topleft;

  -moz-border-radius-topright:        $topright;
  -moz-border-radius-bottomright:     $bottomright;
  -moz-border-radius-bottomleft:      $bottomleft;
  -moz-border-radius-topleft:         $topleft;

  border-top-right-radius:            $topright;
  border-bottom-right-radius:         $bottomright;
  border-bottom-left-radius:          $bottomleft;
  border-top-left-radius:             $topleft;

  -moz-background-clip:    padding;
  -webkit-background-clip: padding-box;
  background-clip:         padding-box;
}

@mixin opacity ($opacity: 0.5) {
  -webkit-opacity: 	$opacity;
  -moz-opacity: 		$opacity;
  opacity: 		$opacity;
}

@mixin gradient ($startColor: #eee, $endColor: white) {
  background-color: $startColor;
  background: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
  background: -webkit-linear-gradient(top, $startColor, $endColor);
  background: -moz-linear-gradient(top, $startColor, $endColor);
  background: -ms-linear-gradient(top, $startColor, $endColor);
  background: -o-linear-gradient(top, $startColor, $endColor);
}
@mixin horizontal-gradient ($startColor: #eee, $endColor: white) {
  background-color: $startColor;
  background-image: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
  background-image: -webkit-linear-gradient(left, $startColor, $endColor);
  background-image: -moz-linear-gradient(left, $startColor, $endColor);
  background-image: -ms-linear-gradient(left, $startColor, $endColor);
  background-image: -o-linear-gradient(left, $startColor, $endColor);
}

@mixin animation ($name, $duration: 300ms, $delay: 0, $ease: ease) {
  -webkit-animation: $name $duration $delay $ease;
  -moz-animation:    $name $duration $delay $ease;
  -ms-animation:     $name $duration $delay $ease;
}

@mixin transition ($transition) {
  -webkit-transition: $transition;
  -moz-transition:    $transition;
  -ms-transition:     $transition;
  -o-transition:      $transition;
}
@mixin transform($string){
  -webkit-transform: $string;
  -moz-transform: 	 $string;
  -ms-transform: 		 $string;
  -o-transform: 		 $string;
}
@mixin scale ($factor) {
  -webkit-transform: scale($factor);
  -moz-transform: 	 scale($factor);
  -ms-transform: 		 scale($factor);
  -o-transform: 		 scale($factor);
}
@mixin rotate ($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: 	 rotate($deg);
  -ms-transform: 		 rotate($deg);
  -o-transform: 		 rotate($deg);
}
@mixin skew ($deg, $deg2) {
  -webkit-transform:       skew($deg, $deg2);
  -moz-transform: 	 skew($deg, $deg2);
  -ms-transform: 		 skew($deg, $deg2);
  -o-transform: 		 skew($deg, $deg2);
}
@mixin translate ($x, $y:0) {
  -webkit-transform:       translate($x, $y);
  -moz-transform: 	 translate($x, $y);
  -ms-transform: 		 translate($x, $y);
  -o-transform: 		 translate($x, $y);
}
@mixin translate3d ($x, $y: 0, $z: 0) {
  -webkit-transform:       translate3d($x, $y, $z);
  -moz-transform: 	 translate3d($x, $y, $z);
  -ms-transform: 		 translate3d($x, $y, $z);
  -o-transform: 		 translate3d($x, $y, $z);
}
@mixin perspective ($value: 1000) {
-webkit-perspective: 	$value;
-moz-perspective: 	$value;
-ms-perspective: 	$value;
perspective: 		$value;
}
@mixin transform-origin ($x:center, $y:center) {
-webkit-transform-origin: $x $y;
-moz-transform-origin:    $x $y;
-ms-transform-origin:     $x $y;
-o-transform-origin:      $x $y;
}
@mixin normal-transition {
  transition: all .25s cubic-bezier(.645, .045, .355, 1);
}
