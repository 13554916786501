// Flex setup
$enable-flex: true;
$enable-cssgrid: true;

// Breakpoint setup
$grid-breakpoints: (
	// Extra small screen / phone
	xs: 0,
	// Small screen / phone
	sm: 544px,
	// Medium screen / tablet
	md: 768px,
	// Large screen / desktop
	lg: 992px,
	// Extra large screen / wide desktop
	xl: 1200px,
	// Super extra screen
	xxl:1920px
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
	sm: 576px,
	md: 720px,
	lg: 940px,
	xl: 1140px,
	xxl: 1400px
);

$font-size-base: 1.2rem;
$font-family-base: "Futura PT", Helvetica, Arial, sans-serif;
$grid-gutter-width: 50px !default;
$link-color: #B08968;
$accordion-button-active-color: #B08968;
$link-decoration: none;
$primary: #9C6644;