.tiny_bullet_slider .tp-bullet:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 25px;
  top: -12px;
  left: 0px;
  background: transparent
}
#rev_slider_1_1_wrapper {
  height: 100vh;
  background: #000;
  padding: 0;
  margin: 0 auto;

  .uranus.tparrows {
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0);

    &:before {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 40px;
      transition: all 0.3s;
      -webkit-transition: all 0.3s
    }
  }
}

#rev_slider_1_1_wrapper .uranus.tparrows.rs-touchhover:before {
  opacity: 0.75
}
.bullet-bar .tp-bullet {
}

.bullet-bar .tp-bullet:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 10px;
  margin-left: -10px;
  margin-top: -10px;
  box-sizing: content-box
}

.bullet-bar .tp-bullet {
  width: 60px;
  height: 3px;
  position: absolute;
  background: #aaa;
  cursor: pointer;
  box-sizing: content-box
}

.bullet-bar .tp-bullet.rs-touchhover, .bullet-bar .tp-bullet.selected {
  background: #eee
}

.bullet-bar .tp-bullet-image {
}

.bullet-bar .tp-bullet-title {
}
