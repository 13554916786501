.custom-swiper { width: calc(100% + 120px)!important; margin: 0 -60px!important; }
@media only screen and (max-width: 1024px) {
  .custom-swiper { width: calc(100% + 30px)!important; margin: 0 -15px!important; }
}
@media only screen and (max-width: 768px) {
  .custom-swiper { width: 100vw!important; }
}

.custom-swiper .swiper-container-horizontal,
.custom-swiper .elementor-container  {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden!important;
  list-style: none;
  z-index: 1;
  cursor: grab;
  padding: 50px 25px;

  @media screen and (max-width: 768px) {
	padding: 50px;
  }

  @media screen and (max-width: 468px) {
	padding: 40px 20px;
  }
}

.swiper-pagination {
  position: relative!important;
  margin: 0 0 30px 0;
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: 0!important;
}
.custom-swiper .swiper-container-horizontal .elementor-row {
  position: relative;
  overflow: initial;
  width: 100%;
  align-items: stretch;
  z-index: 1;
  display: flex!important;
  transition-property: transform;
  box-sizing: content-box;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.elementor-editor-active .custom-swiper .swiper-container-horizontal .elementor-row {
  overflow: scroll;
}
.custom-swiper .swiper-container-horizontal .elementor-column,
.custom-swiper .elementor-container .elementor-column {
  flex-shrink: 0;
  position: relative;
  transition-property: transform;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: initial;
  align-items: initial;
}

.custom-swiper .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23202020'%2F%3E%3C%2Fsvg%3E");
}
.custom-swiper .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23202020'%2F%3E%3C%2Fsvg%3E");}

.custom-swiper .swiper-pagination {
  display: none;
  left: 0;
  right: 0;
}
.custom-swiper .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin: 0 10px 0 0;
}
.custom-swiper .swiper-button-next {
  right: -30px;
}
.custom-swiper .swiper-button-prev  {
  left: -30px;
}
.custom-swiper .swiper-button-next,
.custom-swiper .swiper-button-prev  {
  opacity: 0.5;

  &:hover {
	opacity: 1;
  }
}
@media screen and (max-width: 768px) {

  .custom-swiper .swiper-pagination {
	display: block;
  }

  #index_hero .tm-modern-slider .description-wrap {
	display: none;
  }
}
@media screen and (max-width: 1400px) {
  .custom-swiper .swiper-button-next, .custom-swiper .swiper-button-prev {
	display: none;
  }
  .custom-swiper .swiper-pagination {
	display: block;
  }

}

/*.custom-swiper .swiper-button-next, .custom-swiper .swiper-button-prev {
   display: none;
 }
.custom-swiper:hover .swiper-button-next, .custom-swiper:hover .swiper-button-prev {
  display: block;
}*/
.tm-swiper {
	position: relative;
	z-index: 1;

	&:hover {
		.swiper-nav-button {
			@extend %NormalTransition;
			opacity: 1;
			visibility: visible;
		}
	}

	.swiper-inner {
		position: relative;
	}

	.swiper-container {
		width: 100%;
	}

	.swiper-slide {
		box-sizing: border-box;
		text-align: left;
		overflow: visible;
	}

	.tm-button {
		display: inline-flex;
	}

	// Content Alignment.
	&.v-stretch {
		> .swiper-inner {
			> .swiper-container {
				> .swiper-wrapper {
					> .swiper-slide {
						height: auto;

						> div {
							height: 100%;
						}
					}
				}
			}
		}
	}

	&.v-top {
		> .swiper-inner {
			> .swiper-container {
				> .swiper-wrapper {
					> .swiper-slide {
						display: flex;
						align-self: start;
					}
				}
			}
		}
	}

	&.v-middle {
		> .swiper-inner {
			> .swiper-container {
				> .swiper-wrapper {
					> .swiper-slide {
						display: flex;
						align-self: center;
					}
				}
			}
		}
	}

	&.v-bottom {
		> .swiper-inner {
			> .swiper-container {
				> .swiper-wrapper {
					> .swiper-slide {
						display: flex;
						align-self: flex-end;
					}
				}
			}
		}
	}

	&.h-left {
		> .swiper-inner {
			> .swiper-container {
				> .swiper-wrapper {
					> .swiper-slide {
						display: flex;
						justify-content: flex-start;
					}
				}
			}
		}
	}

	&.h-center {
		> .swiper-inner {
			> .swiper-container {
				> .swiper-wrapper {
					> .swiper-slide {
						display: flex;
						justify-content: center;
					}
				}
			}
		}
	}

	&.h-right {
		> .swiper-inner {
			> .swiper-container {
				> .swiper-wrapper {
					> .swiper-slide {
						display: flex;
						justify-content: flex-end;
					}
				}
			}
		}
	}

	// Smooth Transition.
	&[data-autoplay='1'] {
		.swiper-wrapper {
			transition-timing-function: linear !important;
		}
	}

	// Bullets.
	.swiper-pagination-wrap {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		pointer-events: none;
		display: flex;
		align-items: flex-end;
		justify-content: center;
	}

	.swiper-pagination-inner {
		pointer-events: auto;
	}

	.swiper-pagination {
		position: relative;
		user-select: none;
		outline: none;
		width: auto;

		.progressbar {
			width: 100%;
			height: 2px;
			background: #eee;

			.filled {
				background: #111;
				height: 100%;
			}
		}

		&.swiper-pagination-custom {
			top: auto;
			left: auto;
			bottom: auto;
			right: auto;
		}
	}

	&.bullets-v-align-below {
		.swiper-pagination-wrap {
			position: static;
		}

		.swiper-pagination-wrap {
			margin-top: 44px;
		}
	}

	.swiper-pagination-bullet {
		display: inline-block;
		vertical-align: middle;
		box-sizing: border-box;
		margin: 0 5px;
		width: 20px;
		height: 20px;
		border: 0;
		border-radius: 0;
		background: transparent;
		opacity: 1;
		position: relative;
		outline: none;
		color: #d8d8d8;

		&:hover,
		&.swiper-pagination-bullet-active {
			color: #111;

			&:before {
				width: 12px;
				height: 12px;
			}
		}

		&.swiper-pagination-bullet-active {
			cursor: default;
		}

		&:before {
			@extend %NormalTransition;
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			border-radius: 100%;
			width: 8px;
			height: 8px;
			background: currentColor;
			z-index: 1;
		}

		&:only-child {
			visibility: hidden;
		}
	}

	.fraction {
		display: inline-block;
	}

	.swiper-alt-arrow-button {
		cursor: pointer;
	}

	&.bullets-vertical {
		.swiper-pagination-bullet {
			display: block;
			margin: 0;

			+ .swiper-pagination-bullet {
				margin-top: 10px;
			}
		}
	}

	&.pagination-style-02 {
		.swiper-pagination-bullet {
			width: 22px;
			margin: 0 4px;

			&:before {
				border-radius: 0;
				width: 22px;
				height: 4px;
			}
		}
	}

	&.pagination-style-03,
	&.pagination-style-07 {
		.fraction {
			display: flex;
			align-items: center;
			color: #111;
			max-width: 90vw;
		}

		.current,
		.total {
			flex-shrink: 0;
		}

		.separator {
			flex-grow: 1;
			height: 1px;
			background: currentColor;
		}
	}

	&.pagination-style-03 {
		.fraction {
			font-size: 18px;
			font-weight: 500;
			letter-spacing: 0.75px;
			width: 150px;

			.separator {
				margin-left: 15px;
				margin-right: 15px;
			}

			.current {
				font-size: 30px;
				letter-spacing: 1.25px;
			}
		}
	}

	&.pagination-style-04 {
		.swiper-pagination-inner {
			width: 94px;
			height: 94px;
			line-height: 94px;
			border-radius: 50%;
			background: #fff;
			box-shadow: 0 20px 50px rgba(0, 0, 0, .05);
		}

		.fraction {
			font-size: 17px;
			font-weight: 500;
			color: #111;
			letter-spacing: 1.31px;

			div {
				display: inline-block;
			}
		}

		.swiper-pagination-inner {
			position: relative;
		}

		.swiper-alt-arrow-button {
			user-select: none;
			position: absolute;
			width: 21px;
			height: 21px;
			left: 50%;
			top: 50%;
			z-index: 12;
			background-position: center;
			background-repeat: no-repeat;
		}

		.swiper-alt-arrow-prev {
			transform: translate(-10px, -35px);
			background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAHCAYAAADebrddAAAAAXNSR0IArs4c6QAAAJZJREFUGBltjUEKwyAURNVCs4klTREUWnsAz5P75LRZZCMFCVoj1lkk2LSz8H/nzfAJOWgYhpNS6ol5QOTHsNZq7/1lmqbGOfeqC19hKaUIIdwQWNe16boul8KyFfawEKKNMT42gJlSajnnS9Ebf4bHGHMuQGM/Cj44fDaOI5vnWeec9yt1AT44crTv+3sxrnXg304ptR/PET/We1JFoAAAAABJRU5ErkJggg==");
		}

		.swiper-alt-arrow-next {
			transform: translate(-10px, 15px);
			background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAHCAYAAADebrddAAAAAXNSR0IArs4c6QAAAKBJREFUGBmFjb0KwyAUhWsaqoUMUcgQ6FLo5iv4TL6tW6FLiWKGhiIWgmK9BUNxaO9y7s93zkWMsVNKie7+FELo0Ugpp7ZtX79YuAOHAOKcH7TWl/xhX5tyYhzH8aqUWj8wAMMwdCGEcw3n1Ns8zw72W5L3fu37PsUYu2LAGBtr7VLmDYaFc85TSo/5AyaEPI0xUwFBm+8BeiHEHUDQ+vYGfjU7ajWU+XYAAAAASUVORK5CYII=");
		}

		&.bullets-horizontal {
			.swiper-alt-arrow-prev {
				transform: translate(-40px, -11px) rotate(-90deg);
			}

			.swiper-alt-arrow-next {
				transform: translate(20px, -11px) rotate(-90deg);
			}
		}
	}

	&.pagination-style-05 {
		.swiper-pagination-bullet {
			width: 36px;
			height: 36px;
			margin: 0;

			&:before {
				width: 6px;
				height: 6px;
			}

			&:after {
				@extend %NormalTransition;
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 16px;
				height: 16px;
				opacity: 0;
				border: 1px solid transparent;
				border-radius: 50%;
			}

			&:hover,
			&.swiper-pagination-bullet-active {
				&:after {
					opacity: 1;
					border-color: currentColor;
				}
			}

			&.swiper-pagination-bullet-active {
				&:after {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	&.pagination-style-06 {
		&.bullets-v-align-below {
			.swiper-pagination-wrap {
				margin: 73px 0 0;
			}
		}

		.swiper-pagination-inner {
			width: 770px;
			position: relative;
			height: 44px;
			color: #111;
		}

		.swiper-pagination {
			position: static;
			height: 100%;
		}

		.progressbar {
			height: 2px;
		}

		.fraction {
			position: absolute;
			bottom: 0;
			left: 0;
			font-size: 14px;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 1px;
			color: #111;
		}

		.swiper-alt-arrow-button {
			position: absolute;
			bottom: 0;
			right: 0;
			padding: 12px 0;

			&:before {
				display: block;
				content: '';
				background-color: currentColor;
				-webkit-mask-image: url('/assets/images/swiper-arrow-right.svg');
				mask-image: url('/assets/images/swiper-arrow-right.svg');
				width: 28px;
				height: 9px;
			}
		}
	}

	&.pagination-style-07 {
		.fraction {
			font-size: 24px;
			font-weight: 500;
			width: 280px;

			.separator {
				margin-left: 33px;
				margin-right: 33px;
			}
		}
	}

	// Arrows.
	.swiper-nav-buttons-wrap {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		pointer-events: none;

		.container,
		.row,
		.col-md-12 {
			height: 100%;
		}
	}

	.swiper-nav-buttons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100%;
	}

	.swiper-nav-button {
		transition: all .25s cubic-bezier(.645, .045, .355, 1), visibility .25s linear 2s, opacity .25s linear 2s;
		background-image: none;
		text-align: center;
		user-select: none;
		outline: none;
		width: 48px;
		height: 48px;
		font-size: 18px;
		color: #111;
		border: 1px solid #ddd;
		border-radius: 50%;
		box-shadow: 0 0 5px rgba(0, 0, 0, .01);
		opacity: 0;
		visibility: hidden;
		transform: none;
		position: relative;
		top: auto;
		left: auto;
		right: auto;
		bottom: auto;
		margin: 10px;
		pointer-events: auto;

		&.swiper-button-disabled {
			opacity: 0;
			cursor: auto;
			pointer-events: none;
		}

		&:hover {
			border-color: #111;
			background: #111;
			color: #fff;
		}

		i {
			@extend %FontAwesomeRegular;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		// Disable default arrows.
		&:after {
			display: none;
		}
	}

	&.nav-show-always {
		.swiper-nav-button {
			opacity: 1;
			visibility: visible;
		}
	}

	.swiper-button-prev {
		i {
			&:before {
				content: '\f104';
			}
		}
	}

	.swiper-button-next {
		i {
			&:before {
				content: '\f105';
			}
		}
	}

	.nav-button-text {
		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 13px;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 1px;
	}

	&.nav-style-02 {
		.swiper-nav-button {
			border-top-width: 0 !important;
			border-bottom-width: 0 !important;
			height: 100px;
			width: 100px;
			border-radius: 0;
		}

		.nav-button-text {
			display: block;
		}

		.nav-button-icon {
			display: none;
		}

		.swiper-button-prev {
			border-left: 0 !important;
			border-right: 1px solid #fff;
		}

		.swiper-button-next {
			border-right: 0 !important;
			border-left: 1px solid #fff;
		}
	}
}

@media (max-width: 766px) {
	.tm-swiper {
		&[data-sm-items='auto'] {
			.swiper-slide {
				width: auto !important;

				img {
					width: auto;
				}
			}
		}
	}
}

@media (min-width: 767px) {
	.tm-swiper {
		&[data-md-items='auto'] {
			.swiper-slide {
				width: auto !important;

				img {
					width: auto;
				}
			}
		}
	}
}

@media (min-width: 1024px) {
	.tm-swiper {
		&[data-lg-items='auto'] {
			.swiper-slide {
				width: auto !important;

				img {
					width: auto;
				}
			}
		}
	}
}

@include elementor-breakpoint-down(md) {
	.bullets-tablet-v-align-below {
		.swiper-pagination-wrap {
			position: static;
		}
	}
}

@include elementor-breakpoint-down(sm) {
	.bullets-mobile-v-align-below {
		.swiper-pagination-wrap {
			position: static;
		}
	}
}
