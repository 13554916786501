/* 3.1. Buttons */
/* Animations */
@-webkit-keyframes buttonIconRipple {
  0% {
    border-width: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  80% {
    border-width: 1px;
    -webkit-transform: scale(1.35);
    transform: scale(1.35);
  }
  100% {
    opacity: 0;
  }
}

@keyframes buttonIconRipple {
  0% {
    border-width: 4px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  80% {
    border-width: 1px;
    -webkit-transform: scale(1.35);
    transform: scale(1.35);
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes dotAnimations {
  from {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes dotAnimations {
  from {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes slideFromBottom {
  from {
    -webkit-transform: translateY(105%);
    transform: translateY(105%);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideFromBottom {
  from {
    -webkit-transform: translateY(105%);
    transform: translateY(105%);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes slideToTop {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-105%);
    transform: translateY(-105%);
  }
}

@keyframes slideToTop {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-105%);
    transform: translateY(-105%);
  }
}

.btn {
  border-radius: 0!important;

}

/* Button */
.button {
  display: inline-block;
  padding: 0;
  position: relative;
  border: 2px solid var(--color-primary);
  border-radius: 0;
  font-weight: 500;
  letter-spacing: 1px;
  white-space: nowrap;
  color: var(--color-primary);
  transition: all .3s ease;
  cursor: pointer;
  text-decoration: none!important;
  font-family: var(--font-primary);
  text-align: center;
}
.button-sm {
  font-size: 0.8em;
}
.button > span,
.button strong,
.button i {
  transition: -webkit-transform 0.3s cubic-bezier(0.22, 0.665, 0.34, 0.875);
  transition: transform 0.3s cubic-bezier(0.22, 0.665, 0.34, 0.875);
  transition: transform 0.3s cubic-bezier(0.22, 0.665, 0.34, 0.875), -webkit-transform 0.3s cubic-bezier(0.22, 0.665, 0.34, 0.875);
}

.button > span {
  display: inline-flex;
  padding: 1.15em 3.1em;
  border-radius: inherit;
  border-color: inherit;
  flex-flow: row wrap;
  align-items: center;
}

.button-facebook {
  background: #4267B2;
}

.button.text-uppercase {
  letter-spacing: .1em!important;
}
.button.text-uppercase .button-txt {
  letter-spacing: .1em!important;
}
.button.semi-round {
  border-radius: 2px;
}

.button.round {
  border-radius: 4px;
}

.button.circle {
  border-radius: 50em;
}

.button.disabled {
  pointer-events: none;
  cursor: none;
}

.button.disabled > span,
.button.disabled .button-icon {
  opacity: 0.5;
}

.button.border-none {
  border-width: 0;
}

.button.border-none > span {
  margin: 0;
}

.button.border-none .button-gradient-border rect {
  stroke-width: 0;
}

.button.border-thin {
  border-width: 1px;
}

.button.border-thin > span {
  margin: -0.5px;
}

.button.border-thin .button-gradient-border rect {
  stroke-width: 1px;
}

.button.border-transparent {
  border-color: transparent;
}

.button.border-thick {
  border-width: 2px;
}

.button.border-thick > span {
  margin: -1px;
}

.button.border-thick .button-gradient-border rect {
  stroke-width: 2px;
}

.button.border-thicker {
  border-width: 3px;
}

.button.border-thicker > span {
  margin: -2px;
}

.button.border-thicker .button-gradient-border rect {
  stroke-width: 3px;
}

.button + .button {
  margin-left: .75em;
}

.button.wide > span {
  padding-left: 2.4em;
  padding-right: 2.4em;
}

.button:hover {
  border-color: transparent;
  background-color: var(--color-primary);
  color: #fff;
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05), 0px 8px 8px rgba(0, 0, 0, 0.05), 0px 16px 16px rgba(0, 0, 0, 0.05);
}

.button:hover .button-gradient-bg-hover {
  opacity: 1;
}

/* Sizes*/
.button-xsm > span {
  padding: 0.6em 2em;
}

.button-xsm.wide > span {
  padding-left: 1.7em;
  padding-right: 1.7em;
}

.button-sm > span {
  padding: 0.85em 2.5em;
}

.button-sm.wide > span {
  padding-left: 3em;
  padding-right: 3em;
}

.button-lg > span {
  padding: 1.3em 2.7em;
}

.button-lg.wide > span {
  padding-left: 3.6em;
  padding-right: 3.6em;
}

.button-xlg > span {
  padding: 1.5em 3.5em;
}

.button-xlg.wide > span {
  padding-left: 4.5em;
  padding-right: 4.5em;
}

.button-custom-sized > span {
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 0;
}

.button-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  letter-spacing: 0;
  transition: inherit;
  background-size: 99.99% !important;
}

.button-icon i {
  position: relative;
  z-index: 3;
}

.button-txt + .button-icon {
  margin-right: 0;
  margin-left: 8px;
}

.button-icon:only-child {
  margin-bottom: 0 !important;
}

.button-gradient-bg + .button-icon, .button-icon:first-child {
  margin-left: auto;
  margin-right: auto;
}

.button-icon,
.button-txt {
  position: relative;
  z-index: 3;
  background: transparent;
  border: 0;
}

.button-txt small {
  display: block;
  opacity: 0.7;
  font-size: 1em;
  text-transform: none;
  letter-spacing: normal;
  text-align: left;
}

/* Icon Shapes */
.button-icon-solid .button-icon,
.button-icon-bordered .button-icon {
  width: 2.5em;
  height: 2.5em;
  margin-left: 20px;
  border-width: 1px;
  border-style: solid;
  margin-top: -5%;
  margin-bottom: -5%;
}

.button-icon-solid.button-icon-left .button-txt + .button-icon,
.button-icon-bordered.button-icon-left .button-txt + .button-icon {
  margin-left: 0;
  margin-right: 20px;
}

.button-icon-semi-round .button-icon {
  border-radius: 2px;
}

.button-icon-round .button-icon {
  border-radius: 4px;
}

.button-icon-circle .button-icon {
  border-radius: 50em;
}

.button-icon-circle.button-icon-ripple .button-icon:before {
  content: '';
  display: inline-block;
  position: absolute;
  top: -2px;
  left: -2px;
  bottom: -2px;
  right: -2px;
  border-radius: inherit;
  border: 1px solid white;
  -webkit-animation: buttonIconRipple 2s cubic-bezier(0.23, 1, 0.32, 1) both infinite;
  animation: buttonIconRipple 2s cubic-bezier(0.23, 1, 0.32, 1) both infinite;
}

.button-icon-solid .button-icon {
  border: none;
  background-color: var(--color-primary);
  color: #fff;
}

/* Icon Position */
.button-icon-block .button-icon,
.button-icon-block .button-txt {
  text-align: center;
  margin-left: auto !important;
  margin-right: auto !important;
}

.button-icon-block .button-icon {
  margin-top: 1.5em;
  align-items: center;
  justify-content: center;
}

.button-icon-block .button-txt {
  width: 100%;
}

.button-icon-top .button-icon,
.button-icon-left .button-icon {
  order: -1;
}

.button-icon-left .button-icon {
  margin-left: 0;
  margin-right: 8px;
}

.button-icon-top.button-icon-block .button-icon {
  margin-bottom: 1.5em;
  margin-top: 0;
}

.button-icon-top.button-icon-block.button-icon-xlg .button-icon {
  margin-bottom: 0.5em;
}

/* Icon Sizes */
.button-icon-xsm .button-icon {
  width: 1.5em;
  height: 1.5em;
}

.button-icon-sm .button-icon {
  width: 2em;
  height: 2em;
}

.button-icon-lg .button-icon {
  width: 3.2em;
  height: 3.2em;
}

.button-icon-xlg .button-icon {
  width: 3.5em;
  height: 3.5em;
}

.button-icon-border-thick .button-icon {
  border-width: 2px;
}

.button-icon-border-thicker .button-icon {
  border-width: 3px;
}

/* Button Styles */
.button-solid {
  background: var(--color-primary);
  color: #fff;
}

.button-solid:hover {
  color: #fff;
}

.button-naked {
  border: none;
}

.button-naked > span {
  padding: 0;
}

.button-naked:hover {
  color: var(--color-primary);
  -webkit-transform: translateX(0) !important;
  transform: translateX(0) !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.backgroundcliptext .button-naked:not(.button-icon-solid).button-gradient .button-txt,
.backgroundcliptext .button-naked:not(.button-icon-solid).button-gradient .button-icon i, .backgroundcliptext
.button-underlined:not(.button-icon-solid).button-gradient .button-txt,
.backgroundcliptext
.button-underlined:not(.button-icon-solid).button-gradient .button-icon i {
  background-clip: border-box !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.button-naked > span > .button-gradient-bg,
.button-underlined > span > .button-gradient-bg {
  display: none;
}

.button-underlined {
  border: none;
  background-color: transparent !important;
  position: relative;
}

.button-underlined:before, .button-underlined:after {
  content: '';
  display: inline-block;
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(var(--color-primary), 0.25);
}

.button-underlined:after {
  background: var(--color-primary);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: right center;
  transform-origin: right center;
  transition: -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.button-red:before, .button-red:after {
  background: #ff9c9c;
}
.button-underlined > span {
  padding: 0.1em 0;
}

.button-underlined:hover {
  background-color: transparent;
  color: var(--color-primary);
  -webkit-transform: none;
  transform: none;
  box-shadow: none;
}

.button-underlined:hover:after {
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.button-underlined.border-none:before, .button-underlined.border-none:after {
  height: 0;
}

.button-underlined.border-thin:before, .button-underlined.border-thin:after {
  height: 1px;
}

.button-underlined.border-thick:before, .button-underlined.border-thick:after {
  height: 2px;
}

.button-underlined.border-thicker:before, .button-underlined.border-thicker:after {
  height: 3px;
}

.button-block {
  white-space: normal;
  width: 100%;
  display: block;
}

.button-gradient {
  border: none;
}

.button-gradient > span {
  margin: 0 !important;
}

.button-gradient:hover {
  background-color: transparent;
}

.button-rounded {
  border-radius: 35px; -webkit-border-radius: 35px; -moz-border-radius: 35px; -o-border-radius: 35px;
}

.button-bordered-gradient {
  border: none;
}

.button-bordered-gradient:hover defs stop:first-child {
  stop-color: var(--color-gradient-start)
}

.button-bordered-gradient:hover defs stop:last-child {
  stop-color: var(--color-gradient-stop)
}

.button-gradient-bg {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: all 0.3s ease;
  background: linear-gradient(to right, var(--color-gradient-start) 0%, var(--color-gradient-stop) 100%);
}

.button-gradient-bg-hover {
  z-index: 1;
  opacity: 0;
  background: linear-gradient(to right, var(--color-gradient-stop) 0%, var(--color-gradient-start) 100%);
}

.button-gradient-border {
  width: calc(100% - 1px);
  height: calc(100% - 1px);
  position: absolute;
  fill: transparent;
  stroke: transparent;
  top: 0;
  left: 0;
  z-index: 2;
  overflow: visible !important;
}

.button-gradient-border defs stop {
  transition: all 0.3s ease;
  stop-opacity: 1;
}

.button-gradient-border defs stop:first-child {
  stop-color: var(--color-gradient-start)
}

.button-gradient-border defs stop:last-child {
  stop-color: var(--color-gradient-stop)
}

.button-gradient-border rect {
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke-width: 1;
}

.button-facebook {
  line-height: 48px;
  font-size: 12px;
  text-decoration: none !important;
  background: #4267B2 !important;
  border-color: #4267B2 !important;
}

.button-facebook {
  color: white !important;
  background: #5390ee !important;
}

.button-facebook svg {
  position: absolute;
  left: 12px;
  top: 11px;
}

.button-google {
  color: black !important;
  line-height: 48px;
  font-size: 12px;
  text-decoration: none !important;
  background: white !important;
  border-color: #9a9a9a !important
}

.button-google:hover {
  color: white !important;
  background: #5d5d5d !important;
}

.button-google svg {
  position: absolute;
  left: 13px;
  top: 12px;
}

.ajax-loadmore {
  display: inline-block;
  border: 1px solid;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.ajax-loadmore span {
  display: inline-block;
}

.ajax-loadmore > span {
  display: block;
  padding: 0.75em 2em;
}

.ajax-loadmore .static,
.ajax-loadmore .loading,
.ajax-loadmore .all-loaded {
  display: block;
  transition: opacity 0.3s, visibility 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
  transition: opacity 0.3s, visibility 0.3s, transform 0.3s, -webkit-transform 0.3s;
}

.ajax-loadmore .dots {
  display: block;
}

.ajax-loadmore .dots > span {
  width: 6px;
  height: 6px;
  margin: 0 1px;
  border-radius: 50em;
  background-color: currentColor;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.ajax-loadmore .all-loaded,
.ajax-loadmore .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.ajax-loadmore .loading {
  font-size: 10px;
}

.ajax-loadmore .all-loaded i {
  margin-left: 8px;
}

.ajax-loadmore.items-loading, .ajax-loadmore.all-items-loaded {
  pointer-events: none;
  border-color: transparent;
}

.ajax-loadmore.items-loading .static, .ajax-loadmore.all-items-loaded .static {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ajax-loadmore.items-loading .loading {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ajax-loadmore.items-loading .loading .dots > span {
  -webkit-animation: dotAnimations 0.6s cubic-bezier(0.23, 1, 0.32, 1) infinite alternate backwards;
  animation: dotAnimations 0.6s cubic-bezier(0.23, 1, 0.32, 1) infinite alternate backwards;
}

.ajax-loadmore.items-loading .loading .dots > span:nth-child(0) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.ajax-loadmore.items-loading .loading .dots > span:nth-child(1) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.ajax-loadmore.items-loading .loading .dots > span:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.ajax-loadmore.items-loading .loading .dots > span:nth-child(3) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.ajax-loadmore.all-items-loaded {
  color: #000;
}

.ajax-loadmore.all-items-loaded .all-loaded {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
