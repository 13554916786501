.spacer_10 {
  height: 10px;
}
.spacer_20 {
  height: 20px;
}
.spacer_30 {
  height: 30px;
}
.spacer_40 {
  height: 40px;
}
.spacer_50 {
  height: 50px;
}
.spacer_60 {
  height: 60px;
}