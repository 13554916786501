.vertical-dot-nav {
  position: fixed;
  list-style-type:none;
  padding: 10px 10px 10px 10px;
  opacity:.8;
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0px;
}

.vertical-dot-nav .dot span {
  background-color: #0000000f;
  padding:2px;
  margin-top:0px;
  margin-left:10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor:pointer;
z-index: 99;

}

.vertical-dot-nav .dot.active span {
  background-color: #d6d6d6;
}


