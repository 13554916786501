::-webkit-input-placeholder {
  color: inherit;
  font-weight: 300;
}

:-moz-placeholder { /* Firefox 18- */
  color: inherit;
  font-weight: 300;
}

::-moz-placeholder { /* Firefox 19+ */
  color: inherit;
  font-weight: 300;
}

:-ms-input-placeholder {
  color: inherit;
  font-weight: 300;
}

input[type='text'],
input[type='email'],
input[type='url'],
input[type='password'],
input[type='search'],
input[type='number'],
input[type='tel'] {
  @extend %NormalTransition;
  width: 100%;
  outline: none;
  border: 1px solid #ddd;
  border-radius: 0;
  padding: 3px 20px;
  min-height: 55px;
}

select {
  @extend %NormalTransition;
  padding: 3px 20px;
  min-height: 55px;
  max-width: 100%;
  width: 100%;
  outline: none;
  border: 1px solid #ddd;
  border-radius: 0;
  background: transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAFCAYAAAELY03+AAAABGdBTUEAALGPC/xhBQAAAFFJREFUCB1tTsERwCAIC9MyhnQMprUJPTw9mweGmACWmQFggGSSQCT02lLUCaayAuTubrcNeNr5TaRAZyhd6A1q2hnkQ8IPKl3G/tyPoHaMfwHyzSNHeemKDAAAAABJRU5ErkJggg==') no-repeat center right 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
}

textarea {
  @extend %NormalTransition;
  width: 100%;
  outline: none;
  border: 1px solid #ddd;
  border-radius: 0;
  background: #f5f5f5;
  display: block;
  padding: 12px 20px 3px;
  max-width: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* Remove box shadow from input on ios */
input[type='text'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='password'],
input[type='search'],
input[type='tel'],
textarea {
  -webkit-appearance: none;
}

@-webkit-keyframes autofill {
  to {
	color: #666;
	background: transparent;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

input[type=text], input[type=email], input[type=url], input[type=password], input[type=search], input[type=number], input[type=tel], select, textarea {
  color: #777;
  border-color: #ddd;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  border-width: 0 0 1px 0;
}

input[type=text]:focus, input[type=email]:focus, input[type=url]:focus, input[type=password]:focus, input[type=search]:focus, input[type=number]:focus, input[type=tel]:focus, textarea:focus, select:focus, select:focus, textarea:focus, .select2-search--dropdown .select2-search__field:focus {
  color: #777;
  border-color: var(--color-primary)!important;
  background-color:transparent;
  outline: 0;
  box-shadow: none;
}
button:focus, .btn-close:focus {
  outline: 0!important;
  box-shadow: none!important;
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.8rem) translateX(0.0rem);
  color: black;
}

button, input[type=button], input[type=reset], input[type=submit], .rev-btn, .tm-button, .button {
  font-family: inherit;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase
}


label.error {
  opacity: 1!important;
  border: 0;
  color: red;
}
.form-floating .error + label + label {
  display: none;
}
.form-floating .form-control:-webkit-autofill + label {
  color: black;
}
.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
  transform: scale(.85) translateY(-.5rem) translateX(0rem);
}

.form-check {
  padding-left: 2.5em;
}
.form-check .form-check-input {
  margin-left: -2.5em;
}

.form-control {
  border: 0 solid rgba(0,0,0,.25)!important;
  border-width: 0 0 1px!important;
}
