// slick
.slick-slider {
  position: relative;
  margin-right: -(0 / 2);
  margin-left: -(0 / 2);
  
  .slick-arrow {
	background: white;
	padding: 10px;
	display: inline-block;
	/*@include size(26px, 26px);*/
	text-align: center;
	line-height: 13px;
	font-size: 19px;
	color: black;
	@include transition(all 0.3s ease-in-out 0s);
	position: absolute;
	top: 50%;
	@include translate(0, -50%);
	z-index: 3;
	border: none;
	@include box-shadow(none);
	@include border-radius(50px);
	@media (min-width: 1200px) {
	  @include opacity(0);
	}
	
	.textnav {
	  display: none;
	}
	
	&:hover, &:active, &:focus {
	  /*background-color: $theme-color;*/
	  color: black;
	}
  }
  
  .slick-prev {
	@media (min-width: 1200px) {
	  margin-left: 15px;
	}
  }
  
  .slick-next {
	@media (min-width: 1200px) {
	  margin-right: 15px;
	}
  }
  
  &:hover {
	.slick-arrow {
	  @include opacity(1);
	  margin: 0 !important;
	}
  }
  
  .slick-slide {
	outline: none !important;
	padding-left: 0 / 2;
	padding-right: 0 / 2;
  }
  
  &.show-text {
	.textnav {
	  display: inline-block;
	  margin: 0 2px;
	}
	
	.slick-arrow {
	  width: auto;
	  height: auto;
	  background: transparent !important;
	  font-weight: 500;
	  font-size: 12px;
	  color: black;
	  
	  &:hover, &:active, &:focus {
		color: black;
	  }
	}
	
	.slick-prev {
	  left: 0 / 2;
	  right: inherit;
	}
	
	.slick-next {
	  right: 0 / 2;
	  left: inherit;
	}
  }
  
  &.p-bottom {
	.slick-list {
	  padding-bottom: 5px;
	}
	
	.slick-arrow {
	  top: 100%;
	  @include translateY(-100%);
	}
  }
  
  .slick-track {
	margin: inherit;
  }
  
  // dots
  .slick-dots {
	margin: 0 !important;
	padding: 20px 0 0;
	@media (min-width: 1200px) {
	  padding: 20px 0 0;
	}
	text-align: center;
	list-style: none;
	line-height: 1;
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	
	li {
	  display: inline-block;
	  margin: 0 3px;
	  
	  button {
		border: none;
		display: block;
		text-indent: -9999em;
		@include size(8px, 2px);
		padding: 0;
		background: #e8e8e8;
		@include transition(all 0.2s ease-in-out 0s);
	  }
	  
	  &.slick-active button {
		background: black;
		width: 30px;
	  }
	}
  }
}

.slick-carousel-top {
  .slick-arrow {
	@include border-radius(0px);
	top: -75px;
	@include translate(0, 0);
	@include opacity(1);
	margin: 0 !important;
  }
  
  .slick-next {
	right: 15px;
  }
  
  .slick-prev {
	left: inherit;
	right: 45px;
  }
}

.slick-carousel-products {
  position: relative;
  
  .slick-slider {
	overflow: visible;
	.slick-list {
	  padding-bottom: 74px;
	  margin-bottom: -6px;
	  z-index: 0;
	  -webkit-transform: translate3d(0,0,0);
	  -moz-transform: translate3d(0,0,0);
	  -ms-transform: translate3d(0,0,0);
	  -o-transform: translate3d(0,0,0);
	  transform: translate3d(0,0,0);
	}
	.slick-track {
	  -webkit-transform: translate3d(0,0,0);
	  -moz-transform: translate3d(0,0,0);
	  -ms-transform: translate3d(0,0,0);
	  -o-transform: translate3d(0,0,0);
	  transform: translate3d(0,0,0);
	}
	
	.slick-dots {
	  position: absolute;
	  top: calc(100% - 55px );
	}
  }
  
  .slick-carousel-arrows {
	position: absolute;
	right: 30px;
	top: -60px;
	
	.slick-arrow {
	  font-size: 0;
	  background: transparent;
	  border: 0;
	  width: 34px;
	}
	.slick-disabled {
	  opacity: 0.5;
	}
	.slick-next:before {
	  display: block;
	  font-size: 32px;
	  font-family: "Font Awesome 5 Pro"; font-weight: 400; content: "\f105";
	}
	.slick-prev:before {
	  display: block;
	  font-size: 32px;
	  font-family: "Font Awesome 5 Pro"; font-weight: 400; content: "\f104";
	}
  }
  
  .product-block.grid {
	border-bottom: 0;
  }
}
