.pagination-results { clear: both; margin: 0px 0 20px 0; padding: 16px 0 0 0; position: relative; }

.comment-nav-links,
.page-pagination {
	padding: 0;
	margin: 0;
	font-size: 14px;
	font-weight: 600;
	text-align: center;

	li {
		display: inline-block;
		list-style-type: none;

		> span,
		> a {
			display: block;
			text-align: center;
			line-height: 41px;
			min-width: 45px;
			height: 45px;
			border: 2px solid transparent;
			border-radius: 50%;
			color: #111;
		}

		.current {
			border-color: #111;
		}

		.dots {
			background: none;
			border: 0;
			min-width: 20px;
		}

		.prev,
		.next {
			font-size: 13px;
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 1px;

			span {
				font-size: 12px;
			}
		}

		.prev {
			span {
				margin-right: 9px;
			}
		}

		.next {
			span {
				margin-left: 9px;
			}
		}
	}
}

.comment-nav-links {
	ul {
		padding: 0;
	}
}

#comment-nav-above {
	ul {
		margin: 0 0 50px;
	}
}

#comment-nav-below {
	ul {
		margin: 50px 0 0;
	}
}

.page-links {
	@extend %clearfix;
	clear: both;
	padding-top: 30px;

	> span,
	> a {
		display: block;
		float: left;
		padding: 0 10px;
		text-align: center;
		line-height: 41px;
		min-width: 45px;
		height: 45px;
		border: 2px solid transparent;
		border-radius: 50%;
		color: #111 !important;
		font-size: 14px;
		font-weight: 600;
	}

	> span {
		border-color: #111;
	}
}

.post-navigation {
	position: relative;
}

.nav-links {
	margin-top: 50px;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	justify-content: space-between;

	.nav-item {
		max-width: 48%;
		width: 360px;
	}

	a {
		display: block;
		padding: 47px 30px 57px;
		height: 100%;
		border: 1px solid #eee;
		background: #fff;
		font-size: 24px;
		line-height: 1.375;
		font-weight: 600;
		color: #222;
		word-wrap: break-word;

		div {
			@extend %NormalTransition;
			transition: none;
			position: relative;
			font-size: 14px;
			font-weight: 500;
			color: #999;
			margin-bottom: 28px;

			span {
				@extend %NormalTransition;
				position: absolute;
				top: -3px;
				font-size: 24px;
				color: #222;
			}
		}

		&:hover {
			border-color: transparent;
			color: #fff;

			div {
				color: #fff;

				span {
					color: #fff;
				}
			}
		}
	}

	.previous {
		text-align: left;

		a {
			padding-left: 90px;
		}

		div span {
			left: -61px;
		}
	}

	.next {
		text-align: right;

		a {
			padding-right: 90px;
		}

		div span {
			right: -61px;
		}
	}
}

@include media-breakpoint-down(sm) {
	.nav-links {
		.nav-item {
			width: 100%;
			max-width: 100%;

			+ .nav-item {
				margin-top: 30px;
			}
		}
	}
}
