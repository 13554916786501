

.breadcrumbs {
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 12px;
  font-family: var(--font-primary);

  > li {
    color: inherit;

    a {
      color: black;
    }
  }
}

ul.breadcrumbs > li:not(:last-child):after,
ol.breadcrumb > li:not(:last-child):after {
  content: '\f054';
  margin-left: 0.7em;
  margin-right: 0.7em;
  font-family: "Font Awesome 5 Pro";
  font-size: 0.7em;
  line-height: normal;
  vertical-align: middle;
  color: silver;
}

ul.breadcrumbs > li:hover,
ol.breadcrumb > li:hover {
  opacity: 1;
}

ul.breadcrumbs > li + li:before,
ol.breadcrumb > li + li:before {
  content: none;
}

ul.breadcrumbs > .active,
ol.breadcrumb > .active {
  color: inherit;
}

ul.breadcrumbs span,
ol.breadcrumb span {
  opacity: 0.6;
  transition: opacity 0.3s;
}

ul.breadcrumbs a:hover span,
ol.breadcrumb a:hover span {
  opacity: 1;
}
