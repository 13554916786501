.main-footer {
  padding: 60px 0 40px 0;
  /* box-shadow: 0 -10px 20px #b7b7b736; */
  border-top: 1px solid #e6e6e6;
  //background: white url('/assets/images/bg-footer.jpg') repeat-x 0 101%;
  background-size: 850px;
  
  @media (max-width: 468px) {
    padding: 60px 20px 140px;
    background-size: 880px;
    background-repeat: repeat-x;
    background-position: 170px 100%;
  }
  
  h6 {
    color: black;
   font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  
    @media (max-width: 468px) {
      margin-top: 35px;
    }
  }

  ul {
    li {
      margin-bottom: 0.5rem;
      a {
        text-decoration: none;
      }
    }
  }

  a {
    color: black;
  }
}

.copyright {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #e5e5e5;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  
  a {
    color: #000;
    text-decoration: underline;
  }
}


.cookie .standard-body:before {
  display: none !important;
}

.cookie {
  position: fixed;
  bottom: 0;
  background: #f7f7f7;
  width: 100%;
  overflow: hidden;
  padding: 10px;
  z-index: 1000;
  
  .content {
    color: #000;
    float: left;
    padding: 5px 0;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    
    p {
      margin: 0px;
      padding: 0px;
    }
  }
}

@media (max-width: 767px) {
  .cookie {
    background-position: 330px 0;
  }
}
@media (max-width: 468px) {
  .cookie {
    background-position: 250px 0;
  }
}
