html {
	.row {
		&:before,
		&:after {
			display: block;
		}
	}
}

.alert {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  padding-right: 65px;
  border-radius: 0;

  .close {
	border: 0;
	background: transparent;
	height: 17px;
	font-size: 0;

	&:before, &:after {
	  height: 23px;
	}
  }
}

.nav-tabs .nav-link {
  border-width: 2px;
  border-radius: 0;
  font-weight: 600;
  color: #262626;
  letter-spacing: 3px;
  text-transform: uppercase;
  padding: .75rem 0;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #fff #fff #262626 #fff;
}

.tab-content .tab-pane {
  padding: 1rem 0;
  text-align: left;
}

.card {
  @include border-radius(0px);
}
.accordion {
  --bs-accordion-inner-border-radius: 0!important;
}
.accordion-item {
  background-color: transparent;
  border: none;
}
.accordion-button {
  background: transparent!important;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  box-shadow: none!important;
}

.collapse {
  visibility: visible!important;
}