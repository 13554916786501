.widget {
  @media (max-width: 468px) {
    padding: 15px;
  }
  
  &.price-filter {
    .price_slider {
      margin-bottom: 1em
    }
  
    .price_slider_amount {
      text-align: right;
      line-height: 2.4;
      font-size: .8751em
    }
  
    .price_slider_amount .button {
      font-size: 1.15em;
      float: left
    }
  
    .ui-slider {
      position: relative;
      text-align: left;
      margin-left: .5em;
      margin-right: .5em
    }
  
    .ui-slider .ui-slider-handle {
      position: absolute;
      z-index: 2;
      width: 1em;
      height: 1em;
      background-color: #a46497;
      border-radius: 1em;
      cursor: ew-resize;
      outline: 0;
      top: -.3em;
      margin-left: -.5em
    }
  
    .ui-slider .ui-slider-range {
      position: absolute;
      z-index: 1;
      font-size: .7em;
      display: block;
      border: 0;
      border-radius: 1em;
      background-color: #a46497
    }
  
    .price_slider_wrapper .ui-widget-content {
      border-radius: 1em;
      background-color: #eceded;
      border: 0
    }
  
    .ui-slider-horizontal {
      height: .5em
    }
  
    .ui-slider-horizontal .ui-slider-range {
      top: 0;
      height: 100%
    }
  
    .ui-slider-horizontal .ui-slider-range-min {
      left: -1px
    }
  
    .ui-slider-horizontal .ui-slider-range-max {
      right: -1px
    }
  
    form {
      position: relative;
    }
  
    .ui-slider-horizontal {
      height: 5px
    }
  
    .price_slider_wrapper .ui-widget-content {
      background-color: #eceded
    }
  
    .price_label {
      width: 100%;
      position: relative;
      z-index: 2;
      color: transparent
    }
  
    .price_label span {
      display: inline-block;
      min-width: 90px;
      position: absolute;
      top: 0;
      left: 0;
      text-indent: 0;
      font-size: 14px;
      font-weight: 700;
      color: #000;
      text-align: left
    }
  
    .price_label .to {
      left: auto;
      right: 0;
      text-align: right;
    }
  
    .ui-slider {
      margin-bottom: 1em
    }
  
    .ui-slider .ui-slider-handle,
    .ui-slider .ui-slider-range {
      z-index: 0;
      background-color: #ececec;
      box-shadow: inset 0 2px 5px 0 rgba(255, 255, 255, 0.07);
    }
  
    .ui-slider .ui-slider-range {
      z-index: 0
    }
  
    .ui-slider .ui-slider-handle {
      width: 20px;
      height: 20px;
      top: -8px;
      margin-left: -8px;
      border: 2px solid #a27b3e;
      box-shadow: 2.68px 2.97px 10px 0 rgba(255, 255, 255, 0.15);
      background-color: var(--color-primary);
    }
  
    button[type=submit] {
      width: 100%;
      padding: .9em 2.1em;
      text-align: center;
      justify-content: center
    }
  
    button[type=submit]:hover {
      background-color: var(--color-primary);
    }
  
  }
  
  &.product-categories {
    ul {
      > li {
        border-bottom: 1px solid #e2e2e2;
        padding: 0 0 13px 0;
        
        ul li {
          border-bottom: none;
          padding: 0;
        }
      }
      li {
        position: relative;
        margin: 0 0 10px 0;
        
        .fal, .far, .fa {
          width: 26px;
          text-align: center;
        }
      
        & > a {
          padding: 5px 30px 0 0;
          display: block;
          font-size: 18px;
          text-decoration: none;
        
          &:hover, &.active {
            color: black;
            font-weight: 600;
          }
        }
    
        &.active a {
          color: black;
          font-weight: 600;
        }
      
        .head {
          position: absolute;
          right: 0;
          top: 16px;
        
          a {
            text-decoration: none;
            font-size: 43px;
            line-height: 0;
            font-family: 'Raleway';
            color: silver;
            font-weight: 300;
          
            &:hover {
              color: black;
            }
          }
        }
      
        & > ul {
          list-style: disc;
          margin-left: 35px;
          margin-top: 15px;
          margin-bottom: 15px;
        
          li {
            margin: 0 0 0px;
          }
        
          a {
            font-size: 16px;
          }
        
          & > li > ul {
            margin-left: 45px;
            margin-top: 15px;
            margin-bottom: 15px;
          
            & > li > ul > ul {
              margin-left: 55px;
              margin-top: 15px;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
  
  &.box-checkbox,
  &.box-radio {
    .box-content {
      max-height: 250px;
      overflow: auto;
      margin: 10px 0 0 0;
      padding: 10px 10px 10px 0;
    }
    ul {
      li {
        font-size: 16px;
        line-height: 1.2;
        padding: 0 0 13px 0;
  
        .radio-input, .checkbox-input {
          width: 18px;
          height: 18px;
        }
        label {
          padding: 3px 0px 0 0;
        }
      }
    }
  }
}
