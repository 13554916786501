.v-sep {
  display: flex;
  width: 1px;
  flex-flow: row wrap;
  align-items: stretch;
  flex: 1 auto;
  position: relative;
  
  .v-sep-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #9e9e9e69;
  }
}
