.products {
  .product {
    * {
      color: white;
    }

    .title {
      font-size: 1.0rem;
    }

    a {
      text-decoration: none;
    }

    .price {
      -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
      -o-transform: translateY(-100%);
      transform: translateY(-100%);
      -webkit-transition: all .2s ease-in-out 0s;
      -moz-transition: all .2s ease-in-out 0s;
      -ms-transition: all .2s ease-in-out 0s;
      -o-transition: all .2s ease-in-out 0s;
      opacity: 0;
    }

    .shop-icons {
      position: absolute;
      bottom: 15px;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 1;
      display: none
    }

    &:hover {
      * {
        text-decoration: none;
      }

      .shop-icons {
        display: block;
      }

      .price {
        -webkit-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1;
        font-size: 20px;
        letter-spacing: 1px;
      }
    }
  }
}


.product-info {
  .product-center {
    margin-top: 0;
    position: relative;

    > div {
      position: relative;
      z-index: 0;
    }
  }

  .product-center.col-sm-5 {
    width: 44.444444444444444%;
  }

  .product-name {
    font-size: 30px;
    margin: 0;
    padding: 0;
  }

  .description {
    font-weight: 500;
    line-height: 23px;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;

    p {
      margin: 0;

      a {
        display: block;
      }
    }

    span {
      display: inline-block;
      font-weight: normal;
      padding-left: 0;
    }

    label {
      padding: 0;
      vertical-align: top;
      width: 130px;
    }
  }

  .price {
    background: none;

    .price-new {
      color: var(--color-primary);
      display: inline-block;
      font-size: 25px;
      font-weight: 300;
      line-height: 42px;
      vertical-align: middle;
    }

    .price-old {
      color: #575757;
      display: inline-block;
      text-decoration: line-through;
      vertical-align: top;
    }

    h3 {
      font-size: 20px;
      margin: 0;
      padding: 3px 0 3px 0;
    }
  }

  .options {

    h2 {
      font-size: 20px;
      margin: 0;
      padding: 0;
    }

    .form-group {
      line-height: 23px;
      margin: 0;
      padding: 0;

      label {
        margin: 0;
        padding: 0;
      }
    }

    .error {
      margin: 0;
    }
  }

  .is-countdown {
    background: #f5f5f5;
    left: 0;
    margin: 0 0 26px 0;
    position: relative;
    right: 0;
    top: 0;

    .countdown-amount {
      font-size: 20px;
      font-weight: 600;
      margin: 0 0 -2px 0;
    }

    .countdown-section {
      font-size: 100%;
      padding: 14px 0;
    }
  }

  .options2 {
    padding-bottom: 0;

    > div {
      > label {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }

  .radio-type-button {
    display: inline-block;
    vertical-align: top;
    width: auto;
    margin: 10px 5px 5px 0;
    padding: 0;

    input {
      position: absolute;
    }

    span {
      padding: 0px;
      border: 1px solid rgba(0, 0, 0, .1);
      display: block;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      -o-transition: all 0.2s ease-out;
      -ms-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;

      &:hover {
        border: 1px solid var(--color-primary);
      }
    }

    span.active {
      border: 1px solid var(--color-primary);
    }

    img {
      display: block;
    }
  }

  .radio-type-button2 {
    display: inline-block;
    vertical-align: top;
    width: auto;
    margin: 10px 7px 5px 0;
    padding: 0;

    input {
      visibility: hidden;
      position: absolute;
    }

    span {
      border: 1px solid var(--color-primary);
      color: var(--color-primary);
      min-width: 40px;
      display: block;
      text-align: center;
      padding: 9px 20px 8px 20px;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      -o-transition: all 0.2s ease-out;
      -ms-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 10px;

      &:hover {
        border-color: var(--color-primary);
      }
    }

    span.active {
      background: var(--color-primary);
      border-color: var(--color-primary);
      color: #fff;
    }
  }

  .cart {
    text-align: center;

    .add-to-cart {
      padding: 0 0 20px 0;

      p {
        display: inline-block;
      }

      input {
        display: block;
        background: white;
      }

      button {
        color: white;
        font-weight: 500;
      }
    }

    .minimum {
      padding: 17px 30px 15px 30px;
      border-top: 1px solid #e5e5e5;
    }
  }

  .links {
    padding: 0 26px 0 30px;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;

    a {
      cursor: pointer;
      text-align: center;
      display: inline-block;
      font-weight: 600;
      padding: 10px 22px 5px 0;
      text-transform: uppercase;

      &:last-child {
        padding-right: 0;
      }

      i {
        font-size: 20px;
        margin-right: 8px;
      }
    }
  }

  .review {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 24px;
    margin-top: -7px;
    padding: 0 0 17px 0;
    position: relative;

    > div {
      display: inline-block;
      padding: 7px 0 7px 0;
    }

    a {
      cursor: pointer;

      &:first-of-type {
        margin-left: 10px;
      }
    }
  }

  .nav-tabs .nav-item {
    list-style: none;
  }
  .nav-tabs .nav-link {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    isolation: isolate;
  }
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    isolation: isolate;
  }

  .nav-tabs .nav-link:hover, .nav-tabs .nav-link.disabled {
    opacity: 0.5;
  }


  .accordion-button {
    background-color: inherit;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%) !important;
  }
  .accordion-item {
    border: 0;
  }
  .accordion-button {
    font-size: 1.0rem;
    text-transform: none;
    letter-spacing: inherit;
    font-weight: 500;
  }

  .accordion-button:not(.collapsed) {
    color: #414141;
    font-weight: 600;
  }

  .input-option {
    span {
      margin-right: 10px;
    }
    .btn {
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: 600;
      padding: 0.4rem 1.2rem;
    }
  }

  .input-option .btn-check:checked + .btn-outline-secondary {
    background: var(--color-primary);
    color: white;
  }
}

.quickview {
  .product-info {
    .product-center {
      margin-top: 0;

      &:before {
        display: none;
      }
    }
  }
}

#product.product-info {
  .product-name {
    margin-bottom: 10px;
  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .accordion-item {
    background: transparent;
  }
}

.icon-enquiry[align=left] {
  display: inline-block;
  margin-right: 14px;
  vertical-align: top;
  margin-top: -1px;
  position: relative;
}

.tags_product {
  margin: 0 0 20px 0;
}

.product-block {
  background: #fff;
  border: 1px solid #e5e5e5;
  margin: 50px 0 0 0;
  padding: 30px;
  text-align: center;

  .title-block {
    border-bottom: 2px solid var(--color-primary);
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
    margin: 0 auto 28px auto;
    padding: 6px 0 9px 0;
  }

  .strip-line {
    display: none;
  }

  .block-content {
    clear: both;
  }
}

.product-contact {
  list-style: none;
  margin: 0;
  padding: 2px 0 0 0;

  li {
    margin: 0;
    padding: 30px 0 0 0;

    &:first-child {
      padding: 0;
    }

    p {
      color: #808080;
      margin: 0;
      padding: 10px 0 0 0;
    }
  }
}
