.topbar {
  flex: 0 0 100%;
  max-width: 100%;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  padding: 5px 30px;
  background-color: transparent;
  border-bottom-width: 1px;
  border-bottom-color: #b2b2b230;
  border-bottom-style: solid;
  
  .row {
    align-items: stretch;
    flex: 1 auto;
  }
  
  .row > [class^=col] {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
  }
  
  .btn {
    color: rgb(0, 0, 0);
    font-size: 12px !important;
    font-weight: 500 !important;
    letter-spacing: 0.145em !important;
  }
  
  .v-sep .v-sep-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.09);
  }
  
  > span {
    color: #636363;

    &:hover {
      color: black
    }
  }
  
  .header-module {
    padding-top: 5px;
    padding-bottom: 5px;

    > a:not(.button) {
      color: #636363;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      &:hover {
        background: rgba(0, 0, 0, 0.08);
        color: black
      }
    }
  
    .module-trigger {
      font-size: 17px;
      font-weight: 500;
      letter-spacing: 0.145em;
    }
    
  }
}

#page_index .topbar {
  .header-module {
    padding-top: 5px;
    padding-bottom: 5px;

    a {
      &:hover {
        background: rgba(255, 255, 255, 0.15);
      }
    }
  }
}

.bottom-bar {
  padding: 24px 0;
  border-top: 1px solid #e9e9e9;
  
  .bottom-bar ul a {
    color: #454545
  }
  
  .bottom-bar ul span {
    opacity: 1;
    color: inherit
  }
  
  .bottom-bar ul a:hover {
    color: #000
  }
}

#page_cart, #page_order {
  .topbar {
    background-color: #fff;
    
    .header-module {
      .module-trigger {
        color: rgb(0, 0, 0);
      }
    }
  }
}