.embed-responsive,
.video-player {
	position: relative;
	display: block;
	overflow: hidden;
	padding: 0;
	height: 0;
	margin: 0 0 40px;

	iframe {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}

.embed-responsive-16by9,
.video-player {
	padding-bottom: 56.25%;
}

.instagram-media {
	max-width: 100% !important;
}

.no-padding {
	padding: 0 !important;
}

.full-height {
	min-height: 100vh;
}

.col-content-middle {
	display: flex;
	align-items: center;
}

.font-100 {
	font-weight: 100 !important;
}

.font-200 {
	font-weight: 200 !important;
}

.font-300 {
	font-weight: 300 !important;
}

.font-400 {
	font-weight: 400 !important;
}

.font-500 {
	font-weight: 500 !important;
}

.font-600 {
	font-weight: 600 !important;
}

.font-700 {
	font-weight: 700 !important;
}

.font-800 {
	font-weight: 800 !important;
}

.font-900 {
	font-weight: 900 !important;
}

.display-inline {
	display: inline !important;
}

.display-inline-block {
	display: inline-block !important;
}

.display-block {
	display: block !important;
}

.display-none {
	display: none !important;
}

.image-rounded {
	img {
		border-radius: inherit;
	}
}

.user-select-none {
	user-select: none !important;
}

.text-stroke-01 {
	-webkit-text-fill-color: rgba(0, 0, 0, 0);
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: currentColor;
}

@include media-breakpoint-down(sm) {
	.sm-position-static {
		position: static !important;
	}

	.sm-transform-none {
		transform: none !important;
	}
}

.alignnone {
  margin: 5px 20px 20px 0;
}

.rounded {
  border-radius: 20px;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float:right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
