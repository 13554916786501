.lg-backdrop {
	z-index: 999998 !important;
}

.lg-outer {
	z-index: 999999 !important;
}

.lg-sub-html {
	* {
		color: inherit;
	}
}

.lg-actions {
	.lg-prev,
	.lg-next {
		border: 0;

		&:before,
		&:after {
			color: #fff;
		}
	}
}
