.page-loading {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 999999;
	overflow: hidden;
	transition: opacity .5s;
  background: white;

	.sk-wrap {
		width: 60px;
		height: 60px;
		margin: 0 auto;
		font-size: 0;

		.sk-circle {
			margin: 0 auto;
		}
	}

	.sk-wandering-cubes {
		.sk-cube {
			width: 16px;
			height: 16px;
		}
	}

	.sk-three-bounce {
		@extend %clearfix;
		width: 80px;
		height: 20px;

		.sk-child {
			display: block;
			float: left;
		}

		.sk-bounce2 {
			margin: 0 10px;
		}
	}

	.sk-wave {
		> div {
			width: 7px;
			margin: 0 2px 0 2px;
		}
	}
}

.page-load-inner {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.preloader-wrap {
	display: table;
	width: 100%;
	height: 100%;

	.wrap-2 {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
	}
}

body.loaded {
	.page-loading {
		opacity: 0;
	}
}



.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999999;
	background: rgba(255,255,255,0.95);

	.loading-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: block;
		opacity: 1;
	}

	.spin {
		display: inline-block;
		width: 60px;
		height: 60px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -30px;
		margin-left: -30px;
		background-image: url("data:image/svg+xml;base64,PCEtLSBCeSBTYW0gSGVyYmVydCAoQHNoZXJiKSwgZm9yIGV2ZXJ5b25lLiBNb3JlIEAgaHR0cDovL2dvby5nbC83QUp6YkwgLS0+Cjxzdmcgd2lkdGg9IjM4IiBoZWlnaHQ9IjM4IiB2aWV3Qm94PSIwIDAgMzggMzgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgc3Ryb2tlPSIjMDAwIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSIgc3Ryb2tlLXdpZHRoPSIyIj4KICAgICAgICAgICAgPGNpcmNsZSBzdHJva2Utb3BhY2l0eT0iLjIiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIvPgogICAgICAgICAgICA8cGF0aCBkPSJNMzYgMThjMC05Ljk0LTguMDYtMTgtMTgtMTgiPgogICAgICAgICAgICAgICAgPGFuaW1hdGVUcmFuc2Zvcm0KICAgICAgICAgICAgICAgICAgICBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iCiAgICAgICAgICAgICAgICAgICAgdHlwZT0icm90YXRlIgogICAgICAgICAgICAgICAgICAgIGZyb209IjAgMTggMTgiCiAgICAgICAgICAgICAgICAgICAgdG89IjM2MCAxOCAxOCIKICAgICAgICAgICAgICAgICAgICBkdXI9IjAuOHMiCiAgICAgICAgICAgICAgICAgICAgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiLz4KICAgICAgICAgICAgPC9wYXRoPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
		background-position: center;
		background-size: 38px;
		background-repeat: no-repeat;
		transition-property: opacity, visibility;
		transition-duration: 0.15s;
		transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	}
}

.no-js .preloader {
	display: none;
}