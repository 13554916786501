@media (min-width: 768px) {
  .flex-middle-sm,.flex-top-sm {
	display:-webkit-flex;
	display: flex;
	align-items: center;
	-webkit-align-items: center
  }

  .flex-top-sm {
	align-items: flex-start;
	-webkit-align-items: flex-start
  }
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html {overflow-y: scroll;}

.flex-bottom,.flex-middle,.flex-top {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  -webkit-align-items: center
}

.flex-bottom,.flex-top {
  align-items: flex-start;
  -webkit-align-items: flex-start
}

.flex-bottom {
  align-items: flex-end;
  -webkit-align-items: flex-end
}
.content-fill, .content-fit {
  display: block;
  overflow: hidden;

  img {
    max-width: none;
  }
}

.hide {
  display: none;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-transform-none {
  text-transform: none !important;
}

.text-underline {
  text-decoration: underline;
}

.ltr-sp-0 {
  letter-spacing: 0 !important;
}

.ltr-sp-025 {
  letter-spacing: 0.025em !important;
}

.ltr-sp-05 {
  letter-spacing: 0.05em !important;
}

.ltr-sp-075 {
  letter-spacing: 0.075em !important;
}

.ltr-sp-1 {
  letter-spacing: 0.1em !important;
}

.ltr-sp-135 {
  letter-spacing: 0.135em !important;
}

.ltr-sp-15 {
  letter-spacing: 0.15em !important;
}

.ltr-sp-175 {
  letter-spacing: 0.175em !important;
}

.ltr-sp-2 {
  letter-spacing: 0.2em !important;
}

.lh-1 {
  line-height: 1em !important;
}

.lh-125 {
  line-height: 1.25em !important;
}

.lh-15 {
  line-height: 1.5em !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-style-italic {
  font-style: italic !important;
}

.ws-nowrap {
  white-space: nowrap;
}

.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.color-primary {
  color: #3d59e8;
}

.color-secondary {
  color: var(--color-secondary);
}

.branded [class*=fa-behance] {
  background-color: #1769ff;
}

.branded [class*=fa-behance-square] {
  background-color: #1769ff;
}

.branded [class*=fa-codepen] {
  background-color: #0ebeff;
}

.branded [class*=fa-deviantart] {
  background-color: #05cc47;
}

.branded [class*=fa-digg] {
  background-color: #005be2;
}

.branded [class*=fa-dribbble] {
  background-color: #ea4c89;
}

.branded [class*=fa-facebook] {
  background-color: #3b5998;
}

.branded [class*=fa-facebook-square] {
  background-color: #3b5998;
}

.branded [class*=fa-flickr] {
  background-color: #0063dc;
}

.branded [class*=fa-github] {
  background-color: #4078c0;
}

.branded [class*=fa-google] {
  background-color: #4285f4;
}

.branded [class*=fa-google-plus] {
  background-color: #dd4b39;
}

.branded [class*=fa-instagram] {
  background-color: #405de6;
}

.branded [class*=fa-jsfiddle] {
  background-color: #0084FF;
}

.branded [class*=fa-linkedin] {
  background-color: #0077b5;
}

.branded [class*=fa-medium] {
  background-color: #00ab6c;
}

.branded [class*=fa-paypal] {
  background-color: #003087;
}

.branded [class*=fa-pinterest] {
  background-color: #bd081c;
}

.branded [class*=fa-pinterest-p] {
  background-color: #bd081c;
}

.branded [class*=fa-reddit] {
  background-color: #ff4500;
}

.branded [class*=fa-reddit-square] {
  background-color: #ff4500;
}

.branded [class*=fa-skype] {
  background-color: #00aff0;
}

.branded [class*=fa-slack] {
  background-color: #6ecadc;
}

.branded [class*=fa-snapchat] {
  background-color: #fffc00;
}

.branded [class*=fa-soundcloud] {
  background-color: #ff8800;
}

.branded [class*=fa-spotify] {
  background-color: #1db954;
}

.branded [class*=fa-stack-overflow] {
  background-color: #f48024;
}

.branded [class*=fa-telegram] {
  background-color: #0088cc;
}

.branded [class*=fa-trello] {
  background-color: #0079bf;
}

.branded [class*=fa-tumblr] {
  background-color: #35465c;
}

.branded [class*=fa-twitch] {
  background-color: #6441a5;
}

.branded [class*=fa-twitter] {
  background-color: #1da1f2;
}

.branded [class*=fa-twitter-square] {
  background-color: #1da1f2;
}

.branded [class*=fa-vimeo] {
  background-color: #1ab7ea;
}

.branded [class*=fa-wordpress] {
  background-color: #21759b;
}

.branded [class*=fa-youtube] {
  background-color: #ff0000;
}

.branded [class*=fa-youtube-play] {
  background-color: #ff0000;
}

.branded-text [class*=fa-behance] {
  color: #1769ff;
}

.branded-text [class*=fa-behance-square] {
  color: #1769ff;
}

.branded-text [class*=fa-codepen] {
  color: #0ebeff;
}

.branded-text [class*=fa-deviantart] {
  color: #05cc47;
}

.branded-text [class*=fa-digg] {
  color: #005be2;
}

.branded-text [class*=fa-dribbble] {
  color: #ea4c89;
}

.branded-text [class*=fa-facebook] {
  color: #3b5998;
}

.branded-text [class*=fa-facebook-square] {
  color: #3b5998;
}

.branded-text [class*=fa-flickr] {
  color: #0063dc;
}

.branded-text [class*=fa-github] {
  color: #4078c0;
}

.branded-text [class*=fa-google] {
  color: #4285f4;
}

.branded-text [class*=fa-google-plus] {
  color: #dd4b39;
}

.branded-text [class*=fa-instagram] {
  color: #405de6;
}

.branded-text [class*=fa-jsfiddle] {
  color: #0084FF;
}

.branded-text [class*=fa-linkedin] {
  color: #0077b5;
}

.branded-text [class*=fa-medium] {
  color: #00ab6c;
}

.branded-text [class*=fa-paypal] {
  color: #003087;
}

.branded-text [class*=fa-pinterest] {
  color: #bd081c;
}

.branded-text [class*=fa-pinterest-p] {
  color: #bd081c;
}

.branded-text [class*=fa-reddit] {
  color: #ff4500;
}

.branded-text [class*=fa-reddit-square] {
  color: #ff4500;
}

.branded-text [class*=fa-skype] {
  color: #00aff0;
}

.branded-text [class*=fa-slack] {
  color: #6ecadc;
}

.branded-text [class*=fa-snapchat] {
  color: #fffc00;
}

.branded-text [class*=fa-soundcloud] {
  color: #ff8800;
}

.branded-text [class*=fa-spotify] {
  color: #1db954;
}

.branded-text [class*=fa-stack-overflow] {
  color: #f48024;
}

.branded-text [class*=fa-telegram] {
  color: #0088cc;
}

.branded-text [class*=fa-trello] {
  color: #0079bf;
}

.branded-text [class*=fa-tumblr] {
  color: #35465c;
}

.branded-text [class*=fa-twitch] {
  color: #6441a5;
}

.branded-text [class*=fa-twitter] {
  color: #1da1f2;
}

.branded-text [class*=fa-twitter-square] {
  color: #1da1f2;
}

.branded-text [class*=fa-vimeo] {
  color: #1ab7ea;
}

.branded-text [class*=fa-wordpress] {
  color: #21759b;
}

.branded-text [class*=fa-youtube] {
  color: #ff0000;
}

.branded-text [class*=fa-youtube-play] {
  color: #ff0000;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.star-rating {
  padding: 0;
  margin: 0;
  list-style: none;
}

.star-rating li {
  display: inline-block;
  font-size: 1em;
  line-height: 1.5em;
  color: #ffcc00;
  letter-spacing: 0.1em;
  margin: 0;
}

.star-rating.square li {
  display: inline-flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  font-size: 20px;
  color: #fff;
  background-color: #ff7a4b;
}

.star-rating.square.sm li {
  width: 13px;
  height: 13px;
  font-size: 8px;
}

.overlay-link {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-indent: -99999px;
}

.add-dropcap:first-letter {
  float: left;
  margin-right: 0.1em;
  font-size: 4em;
  line-height: 0.75em;
  font-weight: 700;
  color: #000;
}

[data-hover3d=true] [data-stacking-factor] {
  transition: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.perspective {
  -webkit-perspective: 1800px;
  perspective: 1800px;
}

.will-change {
  will-change: transform, opacity;
}

.transition-none {
  transition: none !important;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute !important;
}

.pos-fix {
  position: fixed !important;
}

.pos-stc {
  position: static !important;
}

.shadow-style-1 {
  box-shadow: 0px 10px 30px rgba(206, 206, 206, 0.36);
  -webkit-box-shadow: 0px 10px 30px rgba(206, 206, 206, 0.36);
  -moz-box-shadow: 0px 10px 30px rgba(206, 206, 206, 0.36);
}

.border-radius-0 {
  border-radius: 0px !important;
}

.border-radius-1 {
  border-radius: 1px !important;
}

.border-radius-2 {
  border-radius: 2px !important;
}

.border-radius-3 {
  border-radius: 3px !important;
}

.border-radius-4 {
  border-radius: 4px !important;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.border-radius-6 {
  border-radius: 6px !important;
}

.border-radius-7 {
  border-radius: 7px !important;
}

.border-radius-8 {
  border-radius: 8px !important;
}

.border-radius-9 {
  border-radius: 9px !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.semi-round {
  border-radius: 2px;
}

.round {
  border-radius: 4px;
}

.circle {
  border-radius: 50em;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.fullwidth {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

img {
  max-width: 100%;
  height: auto;
}

.image-wrapper {
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzOCIgaGVpZ2h0PSIzOCIgdmlld0JveD0iMCAwIDM4IDM4IiBzdHJva2U9InJnYmEoMTAyLDEwMiwxMDIsMC4yNSkiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMSAxKSIgc3Ryb2tlLXdpZHRoPSIyIj48Y2lyY2xlIHN0cm9rZS1vcGFjaXR5PSIuNTUiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIvPjxwYXRoIGQ9Ik0zNiAxOGMwLTkuOTQtOC4wNi0xOC0xOC0xOCI+PGFuaW1hdGVUcmFuc2Zvcm0gYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiB0eXBlPSJyb3RhdGUiIGZyb209IjAgMTggMTgiIHRvPSIzNjAgMTggMTgiIGR1cj0iMXMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9wYXRoPjwvZz48L2c+PC9zdmc+");
  max-height: 100%;
}
.image-wrapper.image-loaded {
  background: none;
}

.lazyload {
  opacity: .0001;
  transition: opacity 1s;

  &.loaded {
	opacity: 1;
  }
}

.reset-ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.single-image {
  img {
	height: auto;
	max-width: 100%;
	vertical-align: top;
  }
}


.loading-icon {
  display: none;
  vertical-align: middle;
  text-align: center;
  opacity: 0;
  transition: 0.5s;
  z-index: -999;
  min-height: 27px;

  .bubble {
	display: inline-block;
	width: 12px;
	height: 12px;
	margin: 0 5px;
	transform: scaleY(-50%);

	&:nth-child(2n) .dot {
	  animation-delay: -0.3s;
	}

	&:nth-child(3n) .dot {
	  animation-delay: 0s;
	}
  }

  .dot {
	display: block;
	border-radius: 50%;
	background-color: black;
	width: 100%;
	height: 100%;
	transform-origin: 50% 50%;
	animation: bubble 1.2s -0.6s infinite ease-out;
	-webkit-animation: bubble 1.2s -0.6s infinite ease-out;
  }
}

.parallax-bg {
  position: relative;
}

.parallax-container,
.parallax-figure {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.parallax-container,
.parallax-figure {
  background-size: inherit;
  background-position: inherit;
  background-repeat: no-repeat;
}

.parallax-container {
  overflow: hidden;
}

[data-responsive-bg][data-parallax]:not(.loaded) .parallax-figure {
  background-image: none !important;
}

.parallax-sentinel {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

.fh-txt {
  position: relative;
  display: inline-block;
}


.page-loading {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 9999;
}

.section-loading {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: absolute;
  background: #ffffffad;
  display: none;
}

.loader-inner {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  background-size: cover;
  background-repeat: no-repeat;
}
.loader-inner > div {
  width: 8px;
  height: 100%;
  display: inline-block;
  float: left;
  margin-left: 2px;
  -webkit-animation: delay 0.8s infinite ease-in-out;
  animation: delay 0.8s infinite ease-in-out;
}
.loader-inner .loader1 {
  background-color: #e39505;
}
.loader-inner .loader2 {
  background-color: #ff5395;
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.loader-inner .loader3 {
  background-color: #84b813;
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.loader-inner .loader4 {
  background-color: #f38ca3;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.loader-inner .loader5 {
  background-color: #da5800;
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.loader-inner.loader-text {
  width: 140px;
  font-size: 15px;
  margin-top: 55px;
  line-height: 18px;
}

.loading-mask                                       { position:fixed!important; width: 100%; height: 100%; top: 0; bottom: 0; left: 0; right: 0; font-size:1.1em; text-align:center; background: rgba(255, 255, 255, 0.92); z-index: 99; }
.loading-mask .loading-container                { position:fixed; top:45%; left:50%; width:300px; margin-left:-150px; color:#181b31; text-align:center; z-index:1000; }
.loading-mask .loading-container .spin          { display: inline-block; text-align: center; margin-top: -55px; }
.loading-mask .loading-container .loading-text  { display: block; margin: 20px 0; }


.spin.dark {
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-top-color: rgba(0, 0, 0, 0.08);
  border-left-color: rgba(0, 0, 0, 0.08);
}
.spin.light {
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-top-color: rgba(255, 255, 255, 0.08);
  border-left-color: rgba(255, 255, 255, 0.08);
}
.spin {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -15px;
  margin-top: -15px;
  height: 30px;
  width: 30px;
  background-color: transparent;
  border-radius: 150px;
  display: inline-block;
  vertical-align: middle;
  -webkit-animation: spin 1s infinite linear;
  -moz-animation: spin 1s infinite linear;
  -ms-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  100% {
	-webkit-transform: rotate(-360deg);
	transform: rotate(-360deg)
  }
}

@keyframes spin {
  100% {
	-webkit-transform: rotate(-360deg);
	transform: rotate(-360deg)
  }
}


.ui-spinner {
  display: inline-flex;
  width: 105px;
  border: none;
  background: 0 0;
  flex-flow: row wrap;
  align-items: center
}

.ui-spinner .ui-spinner-input {
  width: 35px !important;
  margin: 0 2px;
  padding: 0 2px;
  text-align: center;
  background: 0 0
}

.ui-spinner .ui-spinner-input:focus {
  outline: none
}

.ui-spinner .ui-button {
  display: inline-flex;
  width: 32px;
  height: 32px;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  background: 0 0;
  border: 1px solid #000;
  border-radius: 50em;
  align-items: center;
  justify-content: center;
  color: #000;
  cursor: pointer
}

.ui-spinner .ui-button:hover {
  background-color: #000;
  border-color: #000;
  color: #fff
}

.ui-spinner .ui-spinner-up .ui-button-icon, .ui-spinner .ui-spinner-down .ui-button-icon {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  background: 0 0
}

.ui-spinner .ui-spinner-up .ui-button-icon:before, .ui-spinner .ui-spinner-down .ui-button-icon:before {
  display: inline-flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: liquid-icon;
  line-height: 1;
  text-indent: 0
}

.ui-spinner .ui-spinner-down {
  order: -1
}

.ui-spinner .ui-spinner-down .ui-button-icon:before {
  content: '\f1fc'
}

.ui-spinner .ui-spinner-up .ui-button-icon:before {
  content: '\f102'
}
.grecaptcha-badge {
  display: none;
}

.close {
  width: 22px;
  height: 22px;
  opacity: 0.8;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 23px;
  width: 2px;
  background-color: #333;
}
.close.light:before, .close.light:after {
  background-color: #ffffff;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}


.image-wrapper {
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" stroke="rgba(102,102,102,0.25)"%3E%3Cg fill="none" fill-rule="evenodd"%3E%3Cg transform="translate(1 1)" stroke-width="2"%3E%3Ccircle stroke-opacity=".55" cx="18" cy="18" r="18"/%3E%3Cpath d="M36 18c0-9.94-8.06-18-18-18"%3E%3CanimateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"/%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E');
  max-height: 100%;
  background-size: auto!important;
  background-color: white;

  img {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  &.image-loaded {
    background: none;
  }
  &.image-loaded img {
    opacity: 1;
    filter: alpha(opacity=100);
  }
}

.tooltip {
  font-size: 1rem;
  padding: 0.25rem 0.9rem;
}