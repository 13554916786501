@keyframes show-animation {
  0% {
	transform: translateY(20px);
	opacity: 0;
  }
  
  100% {
	transform: translateY(0);
	opacity: 1;
  }
}

@keyframes hide-animation {
  0% {
	transform: translateY(0);
	opacity: 1;
  }
  
  100% {
	transform: translateY(20px);
	opacity: 0;
  }
}

.desktop-menu {
  .sm {
	position: relative;
	z-index: 9999;
  }
  
  .sm,
  .sm ul,
  .sm li {
	display: block;
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: left;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  .sm {
	> li {
	  h1,
	  h2,
	  h3,
	  h4,
	  h5,
	  h6 {
		margin: 0;
		padding: 0;
	  }
	}
	
	ul {
	  display: none;
	}
	
	li,
	a {
	  display: block;
	  position: relative;
	}
	
	> li > a {
	  display: block;
	  color: #818181;
	  font-size: 16px;
	  text-decoration: none;
	  text-transform: uppercase;
	  letter-spacing: 2px;
	  font-family: var(--font-primaryt);
	  
	  @media screen and (max-width: 1280px) {
		font-size: 16px;
	  }
	  
	  &:hover, &.active {
		color: black;
		border-bottom: 1px solid black;
	  }
	  
	  &.disabled {
		cursor: not-allowed;
	  }
	}
  }
  
  // End Smartmenus Core
  
  
  .toggle-sub-menu {
	position: relative;
	margin-left: 15px;
	
	&:before {
	  font-family: 'Font Awesome 5 Pro';
	  content: '\f107';
	  font-size: 11px;
	  position: absolute;
	  top: 50%;
	  right: 0;
	  transform: translate(0, -50%);
	}
  }
  
  .sub-arrow {
	display: none;
  }
  
  .sm-simple {
	position: static;
	display: inline-block;
 
	ul {
	  position: absolute;
	  width: 12em;
	}
	
	> li {
	  display: inline-block;
		padding: 15px 15px;
	  
	  > a {
		display: block;
		padding: 0px 5px;
	 
		@media screen and (max-width: 1280px) {
		  padding: 5px 5px;
		}
	  
	  }
	  
	  
	  &:first-child a {
		padding-left: 0
	  }
	  &:last-child a {
		padding-right: 0
	  }
	}
 
	ul li,
	&.sm-vertical li {
	  float: none;
	}
 
	a {
	  
	  white-space: nowrap;
	  
	  &.current {
		background: #555;
		color: #fff;
	  }
	
	  &.disabled {
		color: #ccc;
	  }
	}
 
	ul li > a,
	&.sm-vertical a {
	  padding: 5px 25px;
	  white-space: normal;
	  font-size: 18px;
	  text-decoration: none;
	}
 
	.sm-nowrap:not(.mega-menu) > li {
	  margin-left: 25px;
	}
	.sm-nowrap:not(.mega-menu) > li > a,
	.sm-nowrap:not(.mega-menu) > li > :not(ul) a {
	  padding-left: 5px!important;
	  white-space: nowrap;
	}
	
	.scroll-up,
	.scroll-down {
	  position: absolute;
	  display: none;
	  visibility: hidden;
	  overflow: hidden;
	  height: 20px;
	  background: #fff;
	}
	
	.scroll-up-arrow,
	.scroll-down-arrow {
	  position: absolute;
	  top: -2px;
	  left: 50%;
	  overflow: hidden;
	  margin-left: -8px;
	  width: 0;
	  height: 0;
	  border-width: 8px;
	  border-style: dashed dashed solid dashed;
	  border-color: transparent transparent #555 transparent;
	}
	
	.scroll-down-arrow {
	  top: 6px;
	  border-style: solid dashed dashed dashed;
	  border-color: #555 transparent transparent transparent;
	}
	
	&:not(.sm-vertical) {
	  .has-mega-menu {
		position: static !important;
		
		.mega-menu {
		  left: 0;
		  right: 0 !important;
		  max-width: 100vw !important;
		  margin: 0 auto!important;
		  width: auto !important;
		  margin-top: 15px!important;

		}
	  }
	}
 
	.children {
	  padding: 20px 0;
	  min-width: 240px !important;
	  border: 0;
	  background-color: #fff;
	  -webkit-box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
	  -moz-box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
	  box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
	  
	  a {
		.toggle-sub-menu {
		  position: absolute;
		  top: 50%;
		  right: 0;
		  
		  &:before {
			right: 30px;
		  }
		}
	  }
	  
	  &:after {
		content: '';
		height: 4px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: #111;
	  }
	
	  &.show-animation {
		animation: show-animation .25s;
	  }
	
	  &.hide-animation {
		animation: hide-animation .25s;
	  }
	}
 
	.simple-menu {
	  a {
		padding: 8px 30px;
	  }
	
	  .toggle-sub-menu {
		&:before {
		  font-size: 8px;
		  content: '\f054';
		}
	  }
	
	  .menu-item-wrap {
		line-height: 0;
	  }
	}
 
	.has-mega-menu {
	  .scroll-up,
	  .scroll-down {
		margin-left: 0 !important;
	  }
	}
 
	.mega-menu {
	  padding: 0px!important;
	  
	  .theme-heading-1 a {
		color: var(--color-primary);
	  }
	  ul {
	  
		position: static;
		display: block;
		width: auto;
		border: 0;
		background: transparent;
		box-shadow: none;
	 
		a {
		  padding: 5px 25px;
		}
	  }
	
	  .menu {
		> li {
		  > a {
			padding: 8px 0;
		  }
		}
	  }
	}
	
	&.sm-vertical {
	  display: block;
	  
	  .has-mega-menu {
		.mega-menu {
		  max-width: calc(100vw - 394px) !important;
		  width: 1170px !important;
		}
	  }
	  
	  > li {
		display: block !important;
		width: 100%;
		
		> a {
		  
		  .menu-item-wrap {
			position: relative;
		  }
		  
		  .toggle-sub-menu {
			position: static;
			
			&:before {
			  right: 0;
			  content: '\f107';
			}
		  }
		}
	  }
	}
  }
  
  .menu--primary {
	text-align: center;
  }
}

.mobile-menu {
  .page-navigation-wrap,
  .page-navigation {
	display: none;
  }
}

body.megamenu-opened:after {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  z-index: 6;
}
