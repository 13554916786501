.divider {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.divider-center {
  margin-left: auto;
  margin-right: auto;
}
.divider-width-100 {
  width: 100%;
}

.divider .holder {
  height: 1px;
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 10%;
}
.divider .holder .line {
  height: 1px;
  border-top: 1px solid #ebebeb;
  display: block;
  position: relative;
  top: 1px;
  width: 100%;
}
.divider .text {
  line-height: 1em;
  font-size: 100%;
  margin: 0;
  word-wrap: break-word;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  padding: 0 .8em;
}