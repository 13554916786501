.contact-form-fields-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: calc(-30px / 2);
  margin-right: calc(-30px / 2);
  margin-bottom: -30px;
}

.contact-column {
  position: relative;
  min-height: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.contact-field-group {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: calc(30px / 2);
  padding-left: calc(30px / 2);
  margin-bottom: 30px;

  .contact-field-textual {
	width: 100%;
	max-width: 100%;
	border: 1px solid #818a91;
	background-color: transparent;
	color: #373a3c;
	vertical-align: middle;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
  }

  .contact-select-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	width: 100%;
  }
}

.contact-screen-only {
  position: absolute;
  top: -10000em;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.screen-reader-text {
  position: absolute;
  top: -10000em;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;

  span {
	position: absolute;
	top: -10000em;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
  }
}

.ui-helper-hidden-accessible {
  position: absolute;
  top: -10000em;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.contact-field-label {
  cursor: pointer;
}

.contact-form {
  h4 {
	text-align: center;
	font-weight: 600;
	margin: 0 0 50px 0;
  }

  .contact-field-textual.contact-size-sm {
	min-height: 50px;
	font-size: 16px;
  }

  .contact-field-textual {
	padding: 0;
  }

  .contact-field-group {
	.contact-field {
	  color: #777;
	}

	&:not(.contact-field-type-upload) {
	  .contact-field {
		&:not(.contact-select-wrapper) {
		  background-color: rgba(255, 255, 255, 0);
		  border-color: #ddd;
		  border-width: 0 0 2px;
		}
	  }
	}

	.contact-select-wrapper {
	  select {
		background-color: rgba(255, 255, 255, 0);
		border-color: #ddd;
		border-width: 0 0 2px;
	  }
	}
  }

  .contact-field-type-submit {
	width: 100%;

	button {
	  border-radius: 50px;
	}

	.contact-button {
	  width: 100%;

	  button {
		border-radius: 50px;
	  }
	}
  }

  .contact-field-group.contact-col-custom {
	flex-shrink: 0;
  }

  .contact-field-group.contact-field-type-submit {
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
  }

  .contact-button-align-end {
	.e-form__buttons {
	  -webkit-box-pack: end;
	  -ms-flex-pack: end;
	  justify-content: flex-end;
	}

	.contact-field-type-submit {
	  -webkit-box-pack: end;
	  -ms-flex-pack: end;
	  justify-content: flex-end;
	}
  }

  .contact-field-group.contact-col-fit {
	flex-grow: 1;
  }

  label.error {
	color: red;
	font-size: 16px;
	margin: 8px 0 0 0;
  }
}

@media (min-width: 768px) {
  .contact-column.contact-col-50 {
	width: 50%;
  }
  .contact-column[data-col="50"] {
	width: 50%;
  }
  .contact-column.contact-col-100 {
	width: 100%;
  }
  .contact-column[data-col="100"] {
	width: 100%;
  }
}
