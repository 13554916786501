input[type='radio']:not(.btn-check) {
	position: relative;
	padding: 5px;
	margin: 0 10px 0 3px;
	cursor: pointer;

	&:before {
		content: '';
		display: block;
		width: 18px;
		height: 18px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		border: 1px solid #ddd;
		border-radius: 50%;
		transition: border-color .3s ease;
	}

	&:after {
		content: '';
		display: block;
		width: 8px;
		height: 8px;
		position: absolute;
		top: 50%;
		left: 50%;
		background: #111;
		border-radius: 50%;
		transform: translate(-50%, -50%) scale(0);
		transition: transform .3s ease;
	}

	&:hover {
		&:before {
			border-color: #111;
		}
	}

	&:checked {
		cursor: auto;

		&:before {
			border-color: #111;
		}

		&:after {
			transform: translate(-50%, -50%) scale(1);
		}
	}
}
